import Button from 'components/Basic/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { toggleRecalculateLayoutModalState } from 'store/slices/EditDeleteOptionsModalSlice';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';

export default function RecalculateLayoutButton(){

	const { selectedRoofIndex, currentActiveFacetEditMode, roofIndexes } = useSelector((state: RootState) => state.toolNewPostions.data);
	const isEditPanelModeActive = useSelector((state: RootState) => state.panelSlice.data.activePanelMode);

	const queriesRunningInBackGround = useQueriesRunningInBackGround();
	const recalculateLayoutQueryRunning = useQueriesRunningInBackGround(['recalculateLayout']);
	
	const disableRecalculateWhenAnyModeIsActiveOrFacetMoved = !!currentActiveFacetEditMode || !!roofIndexes.length || isEditPanelModeActive;

	const dispatch = useDispatch();

	return (
		<Button
			id="recalculate-layout"
			style={{
				background: '#fff',
				border: '0px',
				borderRight: '1px solid #e4e4e4',
				borderLeft: '0px',
				color: '#28373E',
				fontSize: '0.938rem',
				padding: '0px',
				height: '36px',
				width: '40px',
				borderRadius: '0px'
			}} 
			onClick={() => dispatch(toggleRecalculateLayoutModalState({ value: true }))}
			disabled={!selectedRoofIndex || queriesRunningInBackGround || disableRecalculateWhenAnyModeIsActiveOrFacetMoved}
			tooltipContent='Recalculate Layout'
			showLoader={!!recalculateLayoutQueryRunning}>
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M14.61 19C17.0346 19 19.0002 17.0344 19.0002 14.6097C19.0002 12.1851 17.0346 10.2195 14.61 10.2195C12.1853 10.2195 10.2197 12.1851 10.2197 14.6097C10.2197 17.0344 12.1853 19 14.61 19Z" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M15.6638 12.6342L16.0655 13.1478C16.2968 13.4433 16.2542 13.532 15.882 13.532H13.5564C12.9146 13.532 12.854 13.8691 12.854 14.4302M13.5564 16.5854L13.1547 16.0717C12.9238 15.7763 12.966 15.6871 13.3382 15.6871H15.6638C16.3052 15.6871 16.3662 15.3504 16.3662 14.7893" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M8.90244 16.8049H4C2.34315 16.8049 1 15.4617 1 13.8049V4C1 2.34315 2.34315 1 4 1H13.8049C15.4617 1 16.8049 2.34315 16.8049 4V8.90244" stroke="#64686A" strokeWidth="1.6"/>
				<path d="M5.1582 3.19507V7.12212" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M7.12207 5.15869L3.19502 5.15869" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M6.57617 11.1499L3.79933 13.9267" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M6.57617 13.9268L3.79933 11.1499" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M14.1709 4.95728L10.2438 4.95728" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>
		</Button>
	);
}