import Button from 'components/Basic/Button';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { setIrrandiance } from 'store/slices/IrradianceSlice';
import { setEditState } from 'store/slices/ToolSlice';

export function IrradianceToggle() {
	const { showIrradiance } = useSelector((state: RootState) => state.Irradiance.data);
	const { threeDModelEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const dispatch = useDispatch();

	function handleIrradianceClick() {
		// dispatch(setEditState({ enabled: false }));
		dispatch(setIrrandiance({ showIrradiance: !showIrradiance }));
	}

	return <Button style={{
		borderRadius: '20px',
		color: showIrradiance ? '#fff' : '#64686A',
		background: `${showIrradiance ? 'var(--secondary)' : '#fff'}`,
		border: `${showIrradiance ? '1px solid var(--secondary)' : '1px solid #C7CACF'}`
	}}
	onClick={handleIrradianceClick}
	disabled={threeDModelEnabled}
	className={'btnFont0 btnHover ' + `${showIrradiance ? ' active' : ''}`}>
		{/* <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<ellipse cx="8.98856" cy="9.00452" rx="3.30497" ry="3.31898" fill="#64686A"/>
			<ellipse cx="8.98856" cy="9.00452" rx="3.30497" ry="3.31898" fill="white"/>
			<ellipse cx="8.98856" cy="9.00452" rx="3.30497" ry="3.31898" stroke={`${showIrradiance ? '#fff' : '#64686A'}`} strokeWidth="1.54"/>
			<ellipse cx="8.98856" cy="9.00452" rx="3.30497" ry="3.31898" stroke={`${showIrradiance ? '#fff' : '#64686A'}`} strokeWidth="1.54"/>
			<path d="M14.5469 9.26562H16.9998" stroke={`${showIrradiance ? '#fff' : '#64686A'}`} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M14.5469 9.26562H16.9998" stroke={`${showIrradiance ? '#fff' : '#64686A'}`}strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M1 9.29883H3.45291" stroke={`${showIrradiance ? '#fff' : '#64686A'}`} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M1 9.29883H3.45291" stroke={`${showIrradiance ? '#fff' : '#64686A'}`} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M8.84766 14.5371L8.84766 17.0004" stroke={`${showIrradiance ? '#fff' : '#64686A'}`} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M8.84766 14.5371L8.84766 17.0004" stroke={`${showIrradiance ? '#fff' : '#64686A'}`} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M9.05859 1L9.05859 3.4633" stroke={`${showIrradiance ? '#fff' : '#64686A'}`} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M9.05859 1L9.05859 3.4633" stroke={`${showIrradiance ? '#fff' : '#64686A'}`}strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M12.7344 13.127L14.4688 14.8688" stroke={`${showIrradiance ? '#fff' : '#64686A'}`} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M12.7344 13.127L14.4688 14.8688" stroke={`${showIrradiance ? '#fff' : '#64686A'}`} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M3.13281 3.5293L4.86728 5.27111" stroke={`${showIrradiance ? '#fff' : '#64686A'}`} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M3.13281 3.5293L4.86728 5.27111" stroke={`${showIrradiance ? '#fff' : '#64686A'}`} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M4.99219 12.8066L3.25772 14.5485" stroke={`${showIrradiance ? '#fff' : '#64686A'}`} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M4.99219 12.8066L3.25772 14.5485" stroke={`${showIrradiance ? '#fff' : '#64686A'}`} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M14.6758 3.38477L12.9413 5.12658" stroke={`${showIrradiance ? '#fff' : '#64686A'}`} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M14.6758 3.38477L12.9413 5.12658" stroke={`${showIrradiance ? '#fff' : '#64686A'}`} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round"/>
		</svg> */}
		<svg width="18" height="19" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<ellipse cx="9.37676" cy="10.0048" rx="3.48858" ry="3.50336" stroke="#003CFF" strokeWidth="1.6"/>
			<ellipse cx="9.37676" cy="10.0048" rx="3.48858" ry="3.50336" stroke="#64686A" strokeWidth="1.6"/>
			<path d="M15.2441 10.2814H17.8333" stroke="#003CFF" strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M15.2441 10.2814H17.8333" stroke="#64686A" strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M0.944336 10.3154H3.53351" stroke="#003CFF" strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M0.944336 10.3154H3.53351" stroke="#64686A" strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M9.22803 15.8447L9.22803 18.4449" stroke="#003CFF" strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M9.22803 15.8447L9.22803 18.4449" stroke="#64686A" strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M9.45068 1.55554L9.45068 4.15569" stroke="#003CFF" strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M9.45068 1.55554L9.45068 4.15569" stroke="#64686A" strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M13.3306 14.3562L15.1614 16.1948" stroke="#003CFF" strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M13.3306 14.3562L15.1614 16.1948" stroke="#64686A" strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M3.1958 4.22534L5.02663 6.06393" stroke="#003CFF" strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M3.1958 4.22534L5.02663 6.06393" stroke="#64686A" strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M5.1582 14.0181L3.32738 15.8567" stroke="#003CFF" strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M5.1582 14.0181L3.32738 15.8567" stroke="#64686A" strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M15.3799 4.07275L13.5491 5.91134" stroke="#003CFF" strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M15.3799 4.07275L13.5491 5.91134" stroke="#64686A" strokeWidth="1.63016" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
		<span>Irradiance</span>
	</Button>;

}