import { QueryParamsFromParentApp } from '../types';
import { DEFAULT_ELECTRIC_UNIT_PRICE, ENERGY_DERATE_RATE } from '../../../../constants';

export function getInitialState(): QueryParamsFromParentApp {
	return {
		billAmount: 0,
		designUUID: '',
		coordinates: null,
		externalId: '',
		electricUnitPrice: DEFAULT_ELECTRIC_UNIT_PRICE,
		panelKey: '',
		parentAppName: '',
		annualEnergy: 0,
		lastEnergyInputChange: 'billAmount',
		energyDerateRate: ENERGY_DERATE_RATE,
		canRemoveShading: false,
		accessToSettings: false,
	};
}