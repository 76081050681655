import Button from 'components/Basic/Button';
import { ModalContainer, ModalContent, ModalFooter, ModalHeader, SettingsPopup } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { DesignSettings, toggleSettingsModal } from 'store/slices/DesignSettingsSlice';
import { AppDispatch, RootState } from 'store';
import Setback from './Setbacks';
import ConfirmationModal from './Confirmation';
import { useRef, useState } from 'react';
import { changePanel } from 'store/slices/RoofDataSlice/roofDataSlice';
import { inchesToDecimeters } from 'store/slices/DesignSettingsSlice/util';

export default function Settings() {

	const { openModal, setbacks, applyingDesignSettings } = useSelector((state: RootState) => state.designSettigs.data);
	const roofData = useSelector((state: RootState) => state.roofData.data);
	const { uuid, panel, imagerySource } = roofData;
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [disableApplyBtn, setDisableApplyBtn] = useState<boolean>(false);

	const disptach = useDispatch<AppDispatch>();

	const setbacksRef = useRef<DesignSettings['setbacks']>(setbacks);

	function handlePopupCancel() {
		disptach(toggleSettingsModal({ open: false }));
	}

	function handleConfirmation() {
		setShowConfirmation(false);
		const { gutterSetback, nonGutterSetback, obstructionSetback } = setbacksRef.current;
		disptach(changePanel({
			uuid, panelKey: panel.key, source: imagerySource,
			gutterSetback: inchesToDecimeters(gutterSetback),
			nonGutterSetback: inchesToDecimeters(nonGutterSetback),
			obstructionSetback: inchesToDecimeters(obstructionSetback)
		}));
	}

	function handleSetbackChnage(setback: Partial<DesignSettings['setbacks']>, isValid: boolean) {
		setDisableApplyBtn(!isValid);
		if (!isValid) return;
		setbacksRef.current = {
			...setbacksRef.current,
			...setback
		};
	}

	function handleApplyChanges() {
		let hasInputChanged = false;
		Object.keys(setbacksRef.current).forEach((k) => {
			const setbackKey = k as keyof DesignSettings['setbacks'];
			if (setbacksRef.current[setbackKey] !== setbacks[setbackKey]) {
				hasInputChanged = true;
				return;
			}
		});

		if (hasInputChanged) {
			setShowConfirmation(true);
			return;
		}

		handlePopupCancel();
	}

	return (
		<>
			<Button
				id="settings"
				className='btnHover'
				style={{
					border: '1px solid #c7cacf',
					fontSize: '0.938rem',
					height: '36px',
					width: '36px',
					borderRadius: '100%',
					background: 'rgb(255, 255, 255)',
					color: '#64686a',
					padding: '9px',
				}} onClick={() => {
					disptach(toggleSettingsModal({ open: true }));
				}}
				tooltipContent='Settings'
			>
				<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1 15H11M11 15C11 16.1046 11.8954 17 13 17C14.1046 17 15 16.1046 15 15M11 15C11 13.8954 11.8954 13 13 13C14.1046 13 15 13.8954 15 15M15 15H17M17 9H7M7 9C7 10.1046 6.10457 11 5 11C3.89543 11 3 10.1046 3 9M7 9C7 7.89543 6.10457 7 5 7C3.89543 7 3 7.89543 3 9M3 9H1M1 3H11M11 3C11 4.10457 11.8954 5 13 5C14.1046 5 15 4.10457 15 3M11 3C11 1.89543 11.8954 1 13 1C14.1046 1 15 1.89543 15 3M15 3H17" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>

			</Button>

			<SettingsPopup
				open={openModal}
				closeOnDocumentClick={false}
				onClose={() => disptach(toggleSettingsModal({ open: false }))}
			>
				<ModalContainer>
					<ModalHeader>
						Settings
					</ModalHeader>
					<ModalContent>
						<Setback onSetbackChnage={handleSetbackChnage} />
					</ModalContent>

					<ModalFooter>
						<Button disabled={applyingDesignSettings}
							onClick={handlePopupCancel} style={{ backgroundColor: 'var(--label)' }}> Cancel</Button>
						<Button disabled={applyingDesignSettings || disableApplyBtn}
							showLoader={applyingDesignSettings}
							onClick={handleApplyChanges} style={{ borderColor: 'var(--primary)' }}> Apply </Button>
					</ModalFooter>
				</ModalContainer>

			</SettingsPopup>

			<ConfirmationModal onCancel={() => setShowConfirmation(false)} onConfirm={handleConfirmation} open={showConfirmation} />
		</>
	);
}