import { useRefs } from 'contexts/RefContext';
import { useKonvaScale } from 'hooks/useKonvaScale';
import Konva from 'konva';
import { useMemo } from 'react';
import { Circle, Line } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setDeletedObstructionIDs } from 'store/slices/ToolSlice';

const calculateCentroid = (points: number[]) => {
	let sumX = 0, sumY = 0;
	const numPoints = points.length / 2;

	for (let i = 0; i < points.length; i += 2) {
		sumX += points[i];
		sumY += points[i + 1];
	}

	return {
		x: sumX / numPoints,
		y: sumY / numPoints,
	};
};

export default function Obstructions() {

	const { obstructions } = useSelector((state: RootState) => state.roofData.data);
	const { currentActiveFacetEditMode, deletedObstructionIDs, moveStage } = useSelector((state: RootState) => state.toolNewPostions.data);
	const scale = useKonvaScale();
	const dispatch = useDispatch();
	const {konvaRef} = useRefs();

	const shuldListenClickEvents= !moveStage && currentActiveFacetEditMode === 'DELETE_OBSTRUCTION_MODE';

	const obstructionsList = useMemo(() => (obstructions.map((obstruction) => {
		const	transformedCoordinates = obstruction.coordinates.flat().map((point, index) => index % 2 === 0 ? point * scale.x : point * scale.y);
		const centroid = obstruction.assessment === 'RAW_LARGE' ? calculateCentroid(transformedCoordinates) : null;
		return { ... obstruction, coordinates: transformedCoordinates, centroid };
	})), [scale, obstructions]);

	function handleClick(evt: Konva.KonvaEventObject<MouseEvent| Event>){
		dispatch(setDeletedObstructionIDs({obstructionID: evt.target.id()}));
		if(konvaRef?.current)
			konvaRef.current.container().style.cursor = 'default';
	}

	if (!obstructions?.length) {
		return null;
	}

	return (
		<>
			{
				obstructionsList.map((obstruction, index) => {
					if(deletedObstructionIDs.includes(obstruction.id)) return null;
					// SHow filled circle
					if(obstruction.centroid){
						return (<Circle
							name='obstruction'
							id={obstruction.id}
							key={index}
							x={obstruction.centroid.x}
							y={obstruction.centroid.y}
							radius={3}
							listening={shuldListenClickEvents}
							stroke="red"
							fill={'red'}
							strokeWidth={2}
							closed={true}
							onClick={handleClick}
							onTap={handleClick}
							onMouseEnter={() => {
								if(konvaRef?.current)
									konvaRef.current.container().style.cursor = 'pointer';
							}}
							onMouseLeave={() => {
								if(konvaRef?.current)
									konvaRef.current.container().style.cursor = 'default';
							}}
							hitFunc={(context, shape)=>{
								context.beginPath();
								const hitRadius = shape.getAttr('radius') + 10;
								context.arc(0, 0, hitRadius, 0, Math.PI * 2, false);
								context.fillStrokeShape(shape);
							}}
							
						/>);
					}

					return (
						<Line
							name='obstruction'
							id={obstruction.id}
							key={index}
							listening={shuldListenClickEvents}
							points={obstruction.coordinates}
							stroke={'red'}
							strokeWidth={1}
							closed={true}
							onClick={handleClick}
							onTap={handleClick}
							onMouseEnter={() => {
								if(konvaRef?.current)
									konvaRef.current.container().style.cursor = 'pointer';
							}}
							onMouseLeave={() => {
								if(konvaRef?.current)
									konvaRef.current.container().style.cursor = 'default';
							}}
							// hitStrokeWidth={50}
							hitFunc={(context, shape)=>{
								const hitRadius = 5;
								const points = (shape as Konva.Line).points();
								context.beginPath();
								for(let i=0; i<points.length; i=i+2){
									const x = points[i] , y = points[i+1];
									context.arc(x, y, hitRadius, 0, Math.PI * 2, false);
								}
								context.fillStrokeShape(shape);
							
							}}
					
						/>
					);
				})
			}
		</>
	);
}