import axios from 'axios';
import { getToken, createApiUrl } from '../../../../../../util';

export async function recalculateFacetLayoutRequest(payload: {designUuid: string; segmentIds: string[]; panelKey:string}) {
	const token = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	};

	const apiUrl = '/designs/reset-layout';
	const { data } = await axios.post(createApiUrl(apiUrl), payload, config);
	return data;
}
