import Button from 'components/Basic/Button';
import { deleteAllFacetsFromPanelDrawer } from 'components/tool/util';
import { useRefs } from 'contexts/RefContext';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { setThreeDState } from 'store/slices/ToolSlice';
export default function Show3DButton () {
	const { threeDModelEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	// const { editModeEnabled, fullScreenEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	
	const dispatch = useDispatch<AppDispatch>();
	const [loading, setLoading] = useState(false);
	const {konvaRef} = useRefs();
	const handleClick = () => {
		if (!loading) {
			setLoading(true);
			if(!threeDModelEnabled && konvaRef?.current){
				deleteAllFacetsFromPanelDrawer(konvaRef?.current);
			}
		
			dispatch(setThreeDState({ enabled: !threeDModelEnabled }));
			setTimeout(() => {
				setLoading(false);
			}, 500);
		}
	};
	return (
		<Button style={{
			background: `${threeDModelEnabled ? 'var(--secondary)' : '#fff'}`,
			borderColor: `${threeDModelEnabled ? 'var(--secondary)' : '#C7CACF'}`,
			color: threeDModelEnabled? '#fff':'#64686A',
			borderRadius: '20px',
		}} 
		onClick={handleClick} className={'btnFont0 btnHover ' + `${threeDModelEnabled ? ' active' : ''}`}>
			{/* <svg style={{display: 'flex',flexShrink: '0'}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M15.4976 7.90344V6.10564C15.4988 5.67407 15.3844 5.25006 15.1663 4.87769C14.9481 4.50532 14.6342 4.19817 14.2572 3.98819L9.42437 1.30528C9.0649 1.10508 8.66027 1 8.24882 1C7.83737 1 7.43273 1.10508 7.07327 1.30528L2.24046 3.98738C1.86331 4.19743 1.54932 4.5047 1.33117 4.87723C1.11302 5.24976 0.998666 5.67393 1.00001 6.10564V11.3092C0.998811 11.7408 1.11323 12.1648 1.33138 12.5371C1.54952 12.9095 1.86343 13.2167 2.24046 13.4266L7.07327 16.1096C7.43273 16.3098 7.83737 16.4148 8.24882 16.4148C8.66027 16.4148 9.0649 16.3098 9.42437 16.1096L11.4704 14.9738" 
					stroke={threeDModelEnabled? '#fff':'#64686A'} strokeWidth="1.21692" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M15.0081 4.64648L8.25017 8.70614M8.25017 8.70614L1.49219 4.64648M8.25017 8.70614V16.4052" 
					stroke={threeDModelEnabled? '#fff':'#64686A'} strokeWidth="1.21692" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M15.0057 14.2869C16.0703 14.2869 16.9333 13.4238 16.9333 12.3592C16.9333 11.2947 16.0703 10.4316 15.0057 10.4316C13.9411 10.4316 13.0781 11.2947 13.0781 12.3592C13.0781 13.4238 13.9411 14.2869 15.0057 14.2869Z" 
					stroke={threeDModelEnabled? '#fff':'#64686A'} strokeWidth="1.21692"/>
			</svg> */}
			<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M16.6365 8.8425V6.94482C16.6378 6.48929 16.517 6.04172 16.2868 5.64866C16.0565 5.2556 15.7252 4.93139 15.3272 4.70974L10.2259 1.87778C9.84645 1.66646 9.41934 1.55554 8.98503 1.55554C8.55072 1.55554 8.1236 1.66646 7.74417 1.87778L2.64287 4.70889C2.24476 4.93061 1.91334 5.25495 1.68306 5.64817C1.45279 6.0414 1.33209 6.48914 1.33351 6.94482V12.4375C1.33224 12.893 1.45302 13.3406 1.68328 13.7336C1.91355 14.1267 2.24489 14.4509 2.64287 14.6726L7.74417 17.5045C8.1236 17.7158 8.55072 17.8268 8.98503 17.8268C9.41934 17.8268 9.84645 17.7158 10.2259 17.5045L12.3856 16.3056" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M16.1189 5.40564L8.98547 9.69083M8.98547 9.69083L1.85205 5.40564M8.98547 9.69083V17.8176" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M16.1167 15.5798C17.2405 15.5798 18.1514 14.6688 18.1514 13.5451C18.1514 12.4213 17.2405 11.5104 16.1167 11.5104C14.993 11.5104 14.082 12.4213 14.082 13.5451C14.082 14.6688 14.993 15.5798 16.1167 15.5798Z" stroke="#64686A" strokeWidth="1.6"/>
			</svg>
			<span>3D</span>
		</Button>
	);
}