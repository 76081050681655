import {
	AppBoxWrap,
	EnergyDiv,
	ToolDiv,
	AppFlexBoxWrap,
} from './styles';
import ToolWithSilder from '../../components/ToolWithSlider.tsx';
import DisplayEnergy from '../../components/DisplayEnergy';
import Loader from '../../components/Loader';
import { MIN_PANELS_REQUIRED_FOR_DESIGN_TO_QUALIFY, PARENT_EVENTS, SOlAR_QOUTE_APP_NAME } from '../../constants';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { RefProvider } from '../../contexts/RefContext';
import Tool from 'components/tool';

export default function LoadRoofDesign() {
	const { data: currentRoofState, loading } = useSelector((state: RootState) => state.roofData);
	const { parentAppName } = useSelector((state: RootState) => state.QueryParams.data);
	const { fullScreenEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { org } = useSelector((state: RootState) => state.roofData.data);

	if (loading) {
		return <Loader
			loaderText='loading...'
			slider={org.setting?.isB2C}
		/>;
	}

	const notEnoughPanelsToMoveForward = !currentRoofState.org.setting.canEdit &&
		currentRoofState.rasterSolarPanels.length < MIN_PANELS_REQUIRED_FOR_DESIGN_TO_QUALIFY;

	if (notEnoughPanelsToMoveForward) {
		window.parent.postMessage({ id: PARENT_EVENTS.DESIGN_HAS_LESS_THEN_REQUIRED_PANELS }, '*');
		return null;
	} else {
		window.parent.postMessage({ id: PARENT_EVENTS.DESIGN_HAS_SUFFICIENT_PANELS, 
			detail: { maxPanels: currentRoofState.rasterSolarPanels.length } }, '*');
	}

	return (
		<div className="bodyWrap" >
			<AppBoxWrap fullScreenEnabled={fullScreenEnabled}>
				<div className='design-Container' >
					{parentAppName === SOlAR_QOUTE_APP_NAME &&
						<div className='design-head'>
							<h3 className='title mobileview'>Your Home Design</h3>
						</div>}
					<div className='design-body'>
						<RefProvider>
							<AppFlexBoxWrap fullScreenEnabled={fullScreenEnabled} isParentSQ={parentAppName === SOlAR_QOUTE_APP_NAME}>
								<ToolDiv fullScreenEnabled={fullScreenEnabled} isParentSQ={parentAppName === SOlAR_QOUTE_APP_NAME}>
									<ToolWithSilder />
								</ToolDiv>
								<EnergyDiv isParentSQ={parentAppName === SOlAR_QOUTE_APP_NAME} fullScreenEnabled={fullScreenEnabled}>
									<div className='w-full relative'>
										{/* <div className='bar-loader'></div> */}
										<Tool />
									</div>
									<DisplayEnergy />
								</EnergyDiv>
							</AppFlexBoxWrap>
						</RefProvider>
					</div>
				</div>
			</AppBoxWrap>
		</div>
	);
}
