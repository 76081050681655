import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ModalPopup, ModalcontentInfo, ModalbuttonWrap, Modalbutton, ModalbuttonNo, ModaliconInfo } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { setDeleteIndividualPanelsMode, togglePanelModal } from 'store/slices/PanelSlice';

const popupMsg = 'Are you sure you want to cancel Deleted Panels?';

export default function CancelDeletePanelModal() {
	const open  = useSelector((state: RootState) => state.panelSlice.data.cancelDeletePanelModal);

	const dispatch = useDispatch<AppDispatch>();

	const handleYes = () => {
		dispatch(setDeleteIndividualPanelsMode({ enabled: false }));
		dispatch(togglePanelModal({targetModal: 'CANCEL_DELETE_PANEL', val:false}));
	};

	const handleNo = () => {
		dispatch(togglePanelModal({targetModal: 'CANCEL_DELETE_PANEL', val:false}));
	};

	return (
		<Popup
			contentStyle={{ width: '560px', borderRadius: '4px' }}
			open={open}
			closeOnDocumentClick={false}
			onClose={handleNo}>
			<ModalPopup>
				<ModaliconInfo>
					<svg width="50" height="50" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="38.5" cy="38.5" r="35.5" stroke="#E64650" strokeWidth="5.63"/>
						<path d="M38.5 19.7692V44.5035" stroke="#E64650" strokeWidth="5.63" strokeLinecap="round"/>
						<circle cx="38.6229" cy="53.0671" r="2.815" fill="#E64650" stroke="#E64650" strokeWidth="1.9596"/>
					</svg>
				</ModaliconInfo>
				<ModalcontentInfo>
					{popupMsg}
				</ModalcontentInfo>
				<ModalbuttonWrap>
					<ModalbuttonNo onClick={handleNo}>
						No
					</ModalbuttonNo>
					<Modalbutton onClick={handleYes}>
						Yes
					</Modalbutton>
				</ModalbuttonWrap>
			</ModalPopup>
		</Popup>
	);
}