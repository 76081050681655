const initializeState = ()=>({
	activePanelMode: false,
	enableAddPanel: false,
	newlyAddedPanels: [],
	enablePanelMove: false,
	currentMovablePanels: [],
	deleteIndividualPanelMode: false,
	deletedPanels: [],
	currentIndex: 0,
	panelHistory: [],
	cancelAddPanelModal: false,
	cancelMovePanelModal: false,
	cancelDeletePanelModal: false,
});

export {
	initializeState,
};

