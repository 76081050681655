import Button from 'components/Basic/Button';
import { ConfirmationPopup, ModalContainer, ModalContent, ModalFooter, ModalHeader, ModaliconInfo } from './styles';

type Props = {
	open: boolean;
	onConfirm: () => void;
	onCancel: () => void;
	message: string;
}

export default function ConfirmationModal({ open, onConfirm, onCancel, message }: Props) {

	return (
		<ConfirmationPopup
			open={open}
			closeOnDocumentClick={false}
			onClose={onCancel}
		>
			
			<ModalContainer className='confirmation-modal'>
				<ModaliconInfo>
					<svg width="50" height="50" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M4.27955 47.2561C-0.717624 28.459 10.4876 9.18148 29.2654 4.18431C42.604 0.647853 56.2117 5.26063 64.7453 14.9667" stroke="#FEB517" strokeWidth="5.62873" strokeMiterlimit="10" strokeLinecap="round" />
						<path d="M12.0035 61.6506C11.8885 61.5356 11.7927 61.4015 11.6777 61.2865" stroke="#FEB517" strokeWidth="5.62873" strokeMiterlimit="10" strokeLinecap="round" />
						<path d="M72.3237 29.1875C77.3209 47.9846 66.1157 67.2621 47.3379 72.2593C34.0762 75.7765 20.5646 71.2406 12.0117 61.6499" stroke="#FEB517" strokeWidth="5.62873" strokeMiterlimit="10" strokeLinecap="round" />
						<path d="M65.0316 10.4324C65.4251 9.92738 66.2176 10.0377 66.4583 10.6309L69.0495 17.0192C69.2901 17.6124 68.7983 18.2437 68.1642 18.1555L61.3362 17.2054C60.7021 17.1172 60.4013 16.3756 60.7948 15.8706L65.0316 10.4324Z" fill="#FEB517" />
						<path d="M6.41032 57.3931C6.25603 56.7718 6.83218 56.2164 7.4474 56.3935L14.0724 58.2998C14.6876 58.4769 14.8805 59.2535 14.4196 59.6978L9.45612 64.482C8.9952 64.9263 8.22618 64.705 8.07188 64.0837L6.41032 57.3931Z" fill="#FEB517" />
						<path d="M25.2793 42.0253L33.1503 48.7884C33.6812 49.227 34.466 49.1808 34.9276 48.673L54.5937 27.1143" stroke="#FEB517" strokeWidth="5.62873" strokeMiterlimit="10" strokeLinecap="round" />
					</svg>
				</ModaliconInfo>
				<ModalHeader className='confirmation-modal-head'>
					Confirm Action
				</ModalHeader>
				<ModalContent className='confirmation-modal-content'>
					{message}
				</ModalContent>

				<ModalFooter className='confirmation-modal-footer'>
					<Button onClick={() => onCancel()} style={{ color: 'var(--label)', background:'transparent' }}> No</Button>
					<Button onClick={() => onConfirm()} style={{borderColor: 'var(--primary)'}}> Yes  </Button>
				</ModalFooter>
			</ModalContainer>

		</ConfirmationPopup>
	);
}