import Button from 'components/Basic/Button';
import { useRefs } from 'contexts/RefContext';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { batchUpdateToolState } from 'store/slices/ToolSlice';
import { DEFAULT_KONVA_SCALE, DEFAULT_KONVA_STAGE_POSITION, PARENT_EVENTS } from '../../../../constants';
import { useEffect } from 'react';

export default function FullViewBtn() {

	const { fullScreenEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const dispatch = useDispatch();

	useEffect(() => {
		function handleSaveDesignEvent(event: MessageEvent) {
			if (event.data === 'DISABLE_FULL_SCREEN_MODE') {
				dispatch(batchUpdateToolState({
					fullScreenEnabled: false, zoomLevel: DEFAULT_KONVA_SCALE, konvaStagePosition: DEFAULT_KONVA_STAGE_POSITION
				}));
			}
		}
		window.addEventListener('message', handleSaveDesignEvent);
		return () => {
			window.removeEventListener('message', handleSaveDesignEvent);
		};
	});
	
	function toggleFullScreen(enabled= fullScreenEnabled) {
		dispatch(batchUpdateToolState({ fullScreenEnabled: !enabled, zoomLevel: DEFAULT_KONVA_SCALE }));
		window.parent.postMessage(
			{ id: 'FULL_VIEW_MODE', detail: { fullScreenEnabled: !enabled } },
			'*'
		);
	}
	return (
		<Button 
			onClick={()=>toggleFullScreen()}
			style={{
				borderRadius: '100%',
				background: `${fullScreenEnabled ? 'var(--secondary)' : '#fff'}`,
				borderColor: `${fullScreenEnabled ? 'var(--secondary)' : '#C7CACF'}`,
				color: '#64686A',
				padding:'9px'
			}}
			className={'fullscreenviewVisible btnHover ' + `${fullScreenEnabled ? ' active' : ''}`}
		>
			{!fullScreenEnabled && <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1.6665 5.62496V3.24996C1.6665 2.83003 1.83332 2.42731 2.13025 2.13037C2.42718 1.83344 2.82991 1.66663 3.24984 1.66663H5.62484M14.3332 
				10.375V12.75C14.3332 13.1699 14.1664 13.5726 13.8694 13.8695C13.5725 14.1665 13.1698 14.3333 12.7498 14.3333H10.3748M10.3748 1.66663H12.7498C13.1698 
				1.66663 13.5725 1.83344 13.8694 2.13037C14.1664 2.42731 14.3332 2.83003 14.3332 3.24996V5.62496M5.62484 14.3333H3.24984C2.82991 14.3333 2.42718 14.1665 
				2.13025 13.8695C1.83332 13.5726 1.6665 13.1699 1.6665 12.75V10.375" 
				stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>}
			{fullScreenEnabled && <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 11.3125L3.8125 11.3125C4.30978 11.3125 4.78669 11.51 5.13832 11.8617C5.48996 12.2133 5.6875 12.6902 5.6875 13.1875L5.6875 16" stroke="#fff" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M11.3125 16L11.3125 13.1875C11.3125 12.6902 11.51 12.2133 11.8617 11.8617C12.2133 11.51 12.6902 11.3125 13.1875 11.3125L16 11.3125" stroke="#fff" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M16 5.6875L13.1875 5.6875C12.6902 5.6875 12.2133 5.48996 11.8617 5.13832C11.51 4.78669 11.3125 4.30978 11.3125 3.8125L11.3125 1" stroke="#fff" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M5.6875 1L5.6875 3.8125C5.6875 4.30978 5.48996 4.78669 5.13833 5.13833C4.78669 5.48996 4.30978 5.6875 3.8125 5.6875L1 5.6875" stroke="#fff" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>}
		</Button>
	);

}