import ConfirmationModal from 'components/Basic/Modal/Confirmation';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { toggleRecalculateLayoutModalState } from 'store/slices/EditDeleteOptionsModalSlice';
import { recalculateFacetLayoutRequest } from '../ButtonDiv/Facet/RecalculateLayoutBtn/api';
import { changePanel } from 'store/slices/RoofDataSlice/roofDataSlice';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

export default function RecalculateFacetLayoutModal() {
	const dispatch = useDispatch<AppDispatch>();
	const { recalculateLayoutModalOpen } = useSelector((state: RootState) => state.editDeleteOptionsModal.data);
	const { uuid, panel: {key: panelKey}, imagerySource } = useSelector((state: RootState) => state.roofData.data);
	const { selectedRoofIndex } = useSelector((state: RootState) => state.toolNewPostions.data);

	const recalculateLayoutQuery = useQuery('recalculateLayout', async () => {
		if (!selectedRoofIndex) throw new Error('E_NO_SEGMENT_SELETED');
		const payload = {
			designUuid: uuid,
			panelKey,
			segmentIds: [selectedRoofIndex]
		};
		const res = await recalculateFacetLayoutRequest(payload);
		await dispatch(changePanel({ uuid, panelKey, source: imagerySource }));
		return res;
	}, {
		onSuccess: async () => {
			// 
		},
		onError: (error: AxiosError<{ message: string }>) => {
			if (error.response?.data) {
				toast.error(error.response.data?.message || error.message);
			}
		},
		retry: 1,
		enabled: false
	});

	function closeModal() {
		dispatch(toggleRecalculateLayoutModalState({ value: false }));
	}

	function hanldeConfirmation() {
		closeModal();
		recalculateLayoutQuery.refetch();
	}

	return (
		<ConfirmationModal 
			message= "Are you sure you want to recalculate the panel layout of this facet?"
			onConfirm={hanldeConfirmation} 
			open={recalculateLayoutModalOpen} 
			onCancel={()=>closeModal()}/>
	);
}

