/* eslint-disable max-len */
// TO_DO: dynamic options 

import Button from 'components/Basic/Button';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { toggleOrientationModalState } from 'store/slices/ChangeOrientationModalSlice';
import { toggleDeleteModalState } from 'store/slices/DeleteModalSlice';
import { toggleShadingModalState, toggleUndoShadingModalState } from 'store/slices/EditDeleteOptionsModalSlice';
import { EditToolOptionsWrap } from '../styles';
import DeleteObstructionModeToggle from './DeleteObstructionBtn';
import RecalculateLayoutButton from './RecalculateLayoutBtn';

export default function EditToolOptions() {

	const { selectedRoofIndex, drawModeEnabled, newlyCreatedFacets, deletedRoofIndexes, roofIndexes, currentActiveFacetEditMode } =
		useSelector((state: RootState) => state.toolNewPostions.data);
	const { allRoofSegs } = useSelector((state: RootState) => state.roofData.data);
	const { activePanelMode } = useSelector((state: RootState) => state.panelSlice.data);
	const { canRemoveShading } = useSelector((state: RootState) => state.QueryParams.data);
	const dispatch = useDispatch<AppDispatch>();
	const queriesRunningInBackGround = useQueriesRunningInBackGround();
	const checkSelectedRoofIndexExists= selectedRoofIndex != null && !deletedRoofIndexes.includes(selectedRoofIndex);
	const disableDeleteBtn= (!drawModeEnabled && !checkSelectedRoofIndexExists) || (drawModeEnabled && !newlyCreatedFacets.length) || queriesRunningInBackGround || activePanelMode;
	const disableChangeOrientationBtn= (!drawModeEnabled && !checkSelectedRoofIndexExists) || (drawModeEnabled) || queriesRunningInBackGround || activePanelMode;
	const facet = allRoofSegs.find(rs=>rs.id===selectedRoofIndex);
	const disableRotateWhenFacetTranformed = (roofIndexes.length>0);
	const disableFacetOperationWhenFacetEditModeActive= !!currentActiveFacetEditMode;
	const changeOrientationQueryRunning = useQueriesRunningInBackGround(['changeFacetOrientation']);

	return (
		<EditToolOptionsWrap>
			<>
				<Button
					id="change-orientation"
					style={{
						background: '#fff',
						border: '0px',
						borderRight: '1px solid #e4e4e4',
						borderLeft: '0px',
						color: '#28373E',
						fontSize: '0.938rem',
						padding: '0px',
						height: '36px',
						width: '40px',
						borderRadius: '0px'
					}} onClick={() => {
						dispatch(toggleOrientationModalState({ open: true, isLoading: false }));
					}}
					disabled={disableChangeOrientationBtn || disableRotateWhenFacetTranformed || disableFacetOperationWhenFacetEditModeActive}
					showLoader={changeOrientationQueryRunning}
					tooltipContent='Change Orientation'
				>
					<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M20.1216 8.67224C20.1216 5.2099 17.2888 2.06231 13.1969 2.06231L15.0854 4.26562M1 12.4493C1 15.9117 3.83283 19.0593 7.92469 19.0593L6.03614 16.856M7.29517 1L19.8068 13.5117L13.5117 19.8068L1 7.29517L7.29517 1Z" stroke="#64686A" strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
				</Button>
			</>
			{!facet?.isFluxPatched && canRemoveShading && <>
				<Button
					id="remove-shading"
					style={{
						background: '#fff',
						border: '0px',
						borderRight: '1px solid #e4e4e4',
						borderLeft: '0px',
						color: '#28373E',
						fontSize: '0.938rem',
						padding: '0px',
						height: '36px',
						width: '40px',
						borderRadius: '0px'
					}}
					disabled={!selectedRoofIndex || queriesRunningInBackGround || activePanelMode || disableFacetOperationWhenFacetEditModeActive}
					onClick={() => {
						dispatch(toggleShadingModalState({ value: true }));
					}}
					tooltipContent='Remove Shading'
				>
					<svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M14.6516 10.8899C15.3838 15.0396 10.7459 16.7483 8.67114 14.1852C5.25364 17.1144 1.83623 14.0632 2.56862 10.8899C1.10916 9.30868 0.684579 7.363 1.22626 5.88582C1.77166 4.39847 3.29466 3.38443 5.13168 3.20073C6.59639 0.515506 10.0138 0.027472 12.2106 3.20073C13.824 3.07174 15.5659 4.4586 16.1163 6.25215C16.6069 7.85059 16.0895 9.79718 14.6516 10.8899Z" stroke="#64686A" strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M8.67046 6.13123V10.0368M8.67046 20.045V12.5999M8.67046 10.0368L11.4776 8.32813M8.67046 10.0368V12.5999M8.67046 12.5999L5.98535 11.0132" stroke="#64686A" strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
				</Button>
			</>}
			{facet?.isFluxPatched && canRemoveShading && <>
				<Button
					id="undo-remove-shading"
					style={{
						background: '#fff',
						border: '0px',
						borderRight: '1px solid #C7CACF',
						borderLeft: '0px',
						color: '#28373E',
						fontSize: '0.938rem',
						padding: '0px',
						height: '36px',
						width: '40px',
						borderRadius: '0px'
					}}
					disabled={!selectedRoofIndex || queriesRunningInBackGround || disableFacetOperationWhenFacetEditModeActive}
					onClick={() => {
						dispatch(toggleUndoShadingModalState({ value: true }));
					}}
					tooltipContent='Undo Remove Shading'
				>
					<svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M14.6516 10.8899C15.3838 15.0396 10.7459 16.7483 8.67114 14.1852C5.25364 17.1144 1.83623 14.0632 2.56862 10.8899C1.10916 9.30868 0.684579 7.363 1.22626 5.88582C1.77166 4.39847 3.29466 3.38443 5.13168 3.20073C6.59639 0.515506 10.0138 0.027472 12.2106 3.20073C13.824 3.07174 15.5659 4.4586 16.1163 6.25215C16.6069 7.85059 16.0895 9.79718 14.6516 10.8899Z" stroke="#64686A" strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M8.67046 6.13123V10.0368M8.67046 20.045V12.5999M8.67046 10.0368L11.4776 8.32813M8.67046 10.0368V12.5999M8.67046 12.5999L5.98535 11.0132" stroke="#64686A" strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
				</Button>
			</>}
			<>
				<Button
					id="delete"
					title='Delete'
					style={{
						background: '#fff',
						border: '0px',
						borderRight: '1px solid #e4e4e4',
						borderLeft: '0px',
						color: '#28373E',
						fontSize: '0.938rem',
						padding: '0px',
						height: '36px',
						width: '40px',
						borderRadius: '0px'
					}} onClick={() => {
						dispatch(toggleDeleteModalState({ value: true }));
					}}
					disabled={disableDeleteBtn || disableFacetOperationWhenFacetEditModeActive}
					tooltipContent='Delete Facet'>
					<svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M6.9514 9.50903V15.5549M10.919 9.50903V15.5549M1 5.47845H16.8704M15.8785 5.47845L15.0185 17.7133C14.9829 18.2217 14.7589 18.6976 14.3918 19.045C14.0246 19.3923 13.5414 19.5855 13.0397 19.5855H4.83072C4.32896 19.5855 3.84583 19.3923 3.47864 19.045C3.11146 18.6976 2.8875 18.2217 2.85188 17.7133L1.9919 5.47845H15.8785ZM11.9109 5.47845V2.45552C11.9109 2.18828 11.8064 1.93198 11.6204 1.74301C11.4344 1.55404 11.1821 1.44788 10.919 1.44788H6.9514C6.68833 1.44788 6.43604 1.55404 6.25002 1.74301C6.064 1.93198 5.9595 2.18828 5.9595 2.45552V5.47845H11.9109Z" stroke="#64686A" strokeWidth="1.60573" strokeLinecap="round" strokeLinejoin="round" />
					</svg>
				</Button>
				<RecalculateLayoutButton/>
				<DeleteObstructionModeToggle/>
			</>
		</EditToolOptionsWrap>

	);
}