import styled from 'styled-components';

export const SetbacksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
	magin-top:8px;
	padding: 10px 6px;
`;

export const Label = styled.h4`
	margin-top: 3px;
	font-size: 1.15rem;
	font-weight: 500;
	margin-bottom:.35rem;
	color: var(--highlight);
`;

export const SetbackItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
	gap: 3px;
	flex-direction: column;
`;

export const SetbackInputWithLabel = styled.div`
  display: flex;
  justify-content: flex-start;
	align-items: center;
	width: 100%;
	
`;

export const SetbackLabel = styled.span`
	font-weight: 500;
	min-width:130px;
	font-size: 1rem;
	padding-right: 12px;
`;

export const SetbackInput = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
  align-items: center;
	width 100%;
	max-width:86%;

	input{
	line-height:1.35;
	font-size:1rem;
	 padding: 9px 0px 9px 10px;
	}
	 &::-webkit-inner-spin-button {
    width: 14px;
    height: 40px;
}
`;

export const SetbackUnit = styled.span`
  margin-left: 8px;
	color: var(--highlight);
	font-weight:600;
	font-size:0.95rem;
`;

export const SetbackError = styled.span`
	color: red;
	font-weight:400;
	font-size:0.75rem;
	margin-top: 3px;
`;