import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ModalPopup, ModalcontentInfo, ModalbuttonWrap, Modalbutton, ModalbuttonNo } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { toggleWarningModalState } from 'store/slices/WarningSlice';
import { changeFacetOrientation } from '../ChangeOrientation/api';
import { changePanel } from 'store/slices/RoofDataSlice/roofDataSlice';
import { toggleOrientationModalState } from 'store/slices/ChangeOrientationModalSlice';
import { setUserModificationState } from 'store/slices/ToolSlice';

export default function WarningModal() {
	const { open, warningMessage, selectedRoofIdx, noOfPanels } = useSelector((state: RootState) => state.warningModal.data);
	const { allRoofSegs, uuid, panel, imagerySource } = useSelector((state: RootState) => state.roofData.data);
	const dispatch = useDispatch<AppDispatch>();
	
	return (
		<Popup
			contentStyle={{ width: '560px', borderRadius: '4px' }}
			open={open}
			closeOnDocumentClick={false}
			onClose={() => {
				if (open)
					dispatch(toggleWarningModalState({open: false, warningMessage: ''}));
			}}>
			<ModalPopup>
				<ModalcontentInfo>
					{warningMessage}
				</ModalcontentInfo>

				{
					noOfPanels ? 
						<ModalbuttonWrap>
							<ModalbuttonNo onClick={async () => {
								if(!selectedRoofIdx) return;
								dispatch(toggleWarningModalState({open: false, warningMessage: ''}));
								dispatch(toggleOrientationModalState({ open: false, isLoading: true }));
								const selectedRoof = allRoofSegs.find(r => r.id === selectedRoofIdx);
								await changeFacetOrientation(
									selectedRoofIdx,
									uuid, 
									panel.key, 
									selectedRoof?.orientation as string, 
									!!selectedRoof?.userMapped 
								);
								await dispatch(changePanel({ uuid, panelKey: panel.key, source: imagerySource }));
								dispatch(toggleOrientationModalState({ open: false, isLoading: false }));
							}}>
								No
							</ModalbuttonNo>
							<Modalbutton onClick={() => {
								dispatch(toggleWarningModalState({open: false, warningMessage: ''}));
								// if he goes on with the changes then go ahead
								dispatch(setUserModificationState({ hasMadeChanges: true }));
							}}>
								Yes
							</Modalbutton>
						</ModalbuttonWrap>
						:
						<ModalbuttonWrap>
							<Modalbutton onClick={async () => {
								if(!selectedRoofIdx) return;
								dispatch(toggleWarningModalState({open: false, warningMessage: ''}));
								dispatch(toggleOrientationModalState({ open: false, isLoading: true }));
								const selectedRoof = allRoofSegs.find(r => r.id === selectedRoofIdx);
								await changeFacetOrientation( 
									selectedRoofIdx, 
									uuid, 
									panel.key, 
									selectedRoof?.orientation as string,
									!!selectedRoof?.userMapped,
								);
								await dispatch(changePanel({ uuid, panelKey: panel.key, source: imagerySource }));
								dispatch(toggleOrientationModalState({ open: false, isLoading: false }));
							}}>
								Ok
							</Modalbutton>
						</ModalbuttonWrap>
				}
			</ModalPopup>
		</Popup>
	);
}