import Button from 'components/Basic/Button';
import { MIN_ZOOM_LIMIT } from '../../constants';
import { useRefs } from 'contexts/RefContext';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { setZoomLevel } from 'store/slices/ToolSlice';
import { zoomAtAPosition } from 'components/tool/util';

export default function ZoomButtons() {
	const dispatch = useDispatch<AppDispatch>();
	const { konvaRef } =useRefs();
	const {zoomLevel}= useSelector((state: RootState)=>state.toolNewPostions.data);

	function handleZoomOnClick(type: 'in'| 'out'){
		if(!konvaRef?.current) return;
		const stage= konvaRef.current;
		const center = {
			x: stage.width() / 2,
			y: stage.height() / 2
		};
		const updatedZoomLevelAndPositon= zoomAtAPosition(stage, center, type);
		if(!updatedZoomLevelAndPositon) return;

		dispatch(setZoomLevel( updatedZoomLevelAndPositon));
	}

	return (
		<div style={{ 
			boxShadow: '0px 0.5px 1.5px #0000001a',
			backgroundColor:'#fff',
			// marginBottom:'10px',
			borderRadius: '3px'
		}}>
			<Button className='zoombtnHover' style={{
				fontSize: '0.938rem',
				borderRadius: '0px',
				fontWeight: '600',
				background: 'transparent',
				border: 'transparent',
				color: '#666666',
				padding: '0.95em 0.85em',
			}} onClick={() => handleZoomOnClick('in')}
			>
				<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M7.03125 7.7207H1M7.03125 1.68945V7.7207V1.68945ZM7.03125 7.7207V13.752V7.7207ZM7.03125
					 7.7207H13.0625H7.03125Z" stroke="#64686A" strokeWidth="1.90461" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>

			</Button>
			<span style={{
				height: '1px',
				width:'50%',
				margin:'0 auto',
				background: '#EBEBEB',
				display:'flex',
			}}></span>
			<Button className='zoombtnHover' style={{
				fontSize: '0.938rem',
				borderRadius: '0px',
				fontWeight: '600',
				background: 'transparent',
				border: 'transparent',
				color: '#666666',
				padding: '1em 0.85em',
			}} onClick={() => handleZoomOnClick('out')}
			disabled={zoomLevel <= MIN_ZOOM_LIMIT}
			>
				<svg width="15" height="2" viewBox="0 0 15 2" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M13.625 0.837891H8.08973H1" stroke="#64686A" strokeWidth="1.6875" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>

			</Button>
		</div>
	);

}