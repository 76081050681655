import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { changePanel, fetchRoofData } from '../RoofDataSlice/roofDataSlice';
import { decimetersToInches } from './util';
import { SETBACK } from '../../../constants';

export type DesignSettings = {
	setbacks: {
		gutterSetback: number;
		nonGutterSetback: number;
		obstructionSetback: number;
	};
	openModal: boolean;
	applyingDesignSettings: boolean
}

const initialState = {
	data: {
		openModal: false,
		applyingDesignSettings: false,
		setbacks: { //setbacks in inches
			gutterSetback: SETBACK.GUTTER,
			nonGutterSetback: SETBACK.NONGUTTER,
			obstructionSetback: SETBACK.OBSTRUCTION
		}
	}
};

export const designSettingSlice = createSlice({
	name: 'DesignSettings',
	initialState,
	reducers: {
		toggleSettingsModal(state, action: PayloadAction<{ open: boolean }>) {
			state.data.openModal = action.payload.open;
		},
		setSetback(state, action: PayloadAction<{ setback: Partial<DesignSettings['setbacks']> }>) {
			state.data.setbacks = {
				...state.data.setbacks,
				...action.payload.setback,
			};
		}

	}, extraReducers: (builder,) => {
		builder
			.addCase(fetchRoofData.fulfilled, (state, action) => {
				const { gutterSetback, nonGutterSetback, obstructionSetback } = action.payload.roofData;
				state.data.setbacks = {
					gutterSetback: decimetersToInches(gutterSetback || 0),
					nonGutterSetback: decimetersToInches(nonGutterSetback || 0),
					obstructionSetback: decimetersToInches(obstructionSetback || 0),
				};
			})
			.addCase(changePanel.pending, (state) => {
				state.data.applyingDesignSettings = true;
			})
			.addCase(changePanel.rejected, (state) => {
				state.data.applyingDesignSettings = false;
			})
			.addCase(changePanel.fulfilled, (state, action) => {
				const { gutterSetback, nonGutterSetback, obstructionSetback } = action.payload.roofData;
				state.data.openModal = false;
				state.data.applyingDesignSettings = false;
				state.data.setbacks = {
					gutterSetback: decimetersToInches( gutterSetback || 0),
					nonGutterSetback: decimetersToInches(nonGutterSetback || 0),
					obstructionSetback: decimetersToInches(obstructionSetback || 0),
				};
			});
	}
});

export const { toggleSettingsModal, setSetback } = designSettingSlice.actions;
export default designSettingSlice.reducer;