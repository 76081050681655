import axios from 'axios';
import { createApiUrl, getToken } from '../../../../../../util';

export async function deleteObstructions(uuid: string, deletedObstructionsID: string[]){ 
	const token = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	};

	const body = {
		designUuid: uuid,
		ids: deletedObstructionsID
	
	};

	const apiUrl = '/designs/delete-obstructions';
	const { data } = await axios.post(createApiUrl(apiUrl), body, config);
	return data as RawSolarQueryData;
}