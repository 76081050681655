import styled, { } from 'styled-components';

export const PanelOrientationWrap = styled.div`
			display: flex;
			align-items: center;
    	pointer-events: all;
			border: 1px solid rgb(228,228,228);
			border-radius: 91.4939px;
    	overflow: hidden;
			position: absolute;
			top: 40px;
    	left: 50px;
`;

export const LabelOrientation = styled.label`
	display: flex;
			align-items: center;
			cursor: pointer;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

`;