import React, { forwardRef, useEffect, useRef } from 'react';
import { debounce } from '../../util';
import { SliderInput, SliderWrap, TotalPanel } from './styles';
import { RangePercentage } from '../ToolWithSlider.tsx/styles';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { EnergyState } from 'store/slices/EnergySlice/types';
import { updateEnergyStateFromSlider } from 'store/slices/EnergySlice/energySlice';
import { setIntermediateEnergyState, toggleOffsetState } from 'store/slices/OffsetModelSlice/offsetmodelSlice';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { setThreeDState } from 'store/slices/ToolSlice';

type Ref = HTMLInputElement | null;

const getSliderlinearGrad = (val: number) => `linear-gradient(to right, var(--secondary) 0%, var(--secondary) ${val}%, #c4c4c4 ${val}%, #c4c4c4 100%)`;

const Slider = forwardRef<Ref>(function Slider(_, ref) {
	const innerRef = useRef<Ref>(null);
	const { totalNoOfPanelsSelectedByUser } = useSelector((state: RootState) => state.energyData.data as unknown as EnergyState);
	const { offsetIndex, rasterSolarPanels, org } = useSelector((state: RootState) => state.roofData.data);
	const { userHasGivenPermToExceedOffset } = useSelector((state: RootState) => state.offsetModal.data);
	const queriesRunningInBackGround = useQueriesRunningInBackGround();
	const { roofIndexes } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { threeDModelEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const disable3D = () => {
		dispatch(setThreeDState({ enabled: false }));
	};
	const dispatch = useDispatch();

	useEffect(() => {
		if (!innerRef.current) return;
		innerRef.current.value = totalNoOfPanelsSelectedByUser.toString();
		const rangeVl = (totalNoOfPanelsSelectedByUser / rasterSolarPanels.length) * 100;
		innerRef.current.style.background = getSliderlinearGrad(rangeVl);
	}, [totalNoOfPanelsSelectedByUser, rasterSolarPanels.length]);

	const debounceDispatch = debounce((value: number) => {
		let energy = 0, irradiance = 0;
		const selectedPanels = [];

		for (let i = 0; i < value; i++) {
			// rasterSolarPanels.filter(p => !roofIndexesToNegate.includes(p.segmentId)),
			if (roofIndexes.includes(rasterSolarPanels[i].segmentId)) continue;
			energy += rasterSolarPanels[i].yearlyEnergyDcKwh;
			irradiance += rasterSolarPanels[i].irradiance,
			selectedPanels.push(rasterSolarPanels[i].id);
		}
		dispatch(updateEnergyStateFromSlider({
			energy: +energy.toFixed(2),
			selectedPanels,
			irradiance: +irradiance.toFixed(2),
		}));
	}, 30);

	return (<>
		<RangePercentage>
			<TotalPanel>({totalNoOfPanelsSelectedByUser})</TotalPanel>
		</RangePercentage>
		<SliderWrap>
			<SliderInput
				className='slider-bar'
				disabled={queriesRunningInBackGround}
				type='range'
				ref={(elm) => {
					innerRef.current = elm;
					if (typeof ref === 'function') ref(elm);
					else if (ref) ref.current = elm;
				}}
				min={0}
				max={rasterSolarPanels.length}
				defaultValue={totalNoOfPanelsSelectedByUser}
				step={1}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					let value = Math.ceil(+e.target.value);
					// check here is to check if the slider
					if (threeDModelEnabled)
						disable3D();
					if (offsetIndex?.offset120 && (value > offsetIndex.offset120)) {
						if (!userHasGivenPermToExceedOffset.offset120 && org.setting?.enableOffsetWarning) {
							dispatch(toggleOffsetState({ value: true }));
							value = offsetIndex.offset150 && value > offsetIndex.offset150 ? offsetIndex.offset150 : value;
							dispatch(
								setIntermediateEnergyState({
									value: { source: 'slider', data: { panelCount: value } },
								})
							);
							e.preventDefault();
							value = offsetIndex.offset120;
						}
					}
					if (offsetIndex?.offset150 && (value > offsetIndex.offset150)) {
						if (!userHasGivenPermToExceedOffset.offset150 && org.setting?.enableOffsetWarning) {
							dispatch(toggleOffsetState({ value: true }));
							dispatch(
								setIntermediateEnergyState({
									value: { source: 'slider', data: { panelCount: value } },
								})
							);
							e.preventDefault();
							value = offsetIndex.offset150;
						}
					}
					e.target.value = value.toString();
					const rangeVl = (value / rasterSolarPanels.length) * 100;
					if (innerRef.current)
						innerRef.current.style.background = getSliderlinearGrad(rangeVl);
					debounceDispatch(value);
				}}
			/>
		</SliderWrap>
	</>
	);
});

export default Slider;
