import axios from 'axios';
import { createApiUrl, getToken } from '../../util';

type CreateDesignReqPayload= {
	externalID: string;
	billAmount: number;
	panelKey?: string;
	gutterSetback?: number;
	nonGutterSetback?:number; 
	obstructionSetback?: number;
}

async function createNewDesign(coord: string | undefined, payload: CreateDesignReqPayload ) {
	const myToken = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${myToken}`
		}
	};

	if (!coord) return;
	const [lat, lng] = coord.split(',').map(eachVal => Number(eachVal));

	const requestBody: CreateDesignReqPayload & { latLng: Coordinate } = {
		latLng: {
			latitude: lat,
			longitude: lng
		},
		...removeUndefinedKeys(payload)
		
	};

	const { data } = await axios.post(createApiUrl('/designs'), requestBody, config);

	return data;
}

type productionDataType = {
	systemSize: number,
	energy: number,
	offset: number,
	totalPanels: number
}

type DesignSaveObj = {
	selectedPanels: string[],
	productionData: productionDataType,
	billAmount: number,
	annualEnergy?: number,
	panelKey: string,
	source: string,
}

async function updateDesing(designSaveObj: DesignSaveObj, uuid: string) {

	const myToken = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${myToken}`
		}
	};

	await axios.patch(createApiUrl(`/designs/${uuid}`), designSaveObj, config);
}

async function getUnselectedPanels(uuid: string) {
	const myToken = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${myToken}`
		}
	};

	const { data } = await axios.get(createApiUrl(`/designs/${uuid}`), config);
	return data;
}

function removeUndefinedKeys<T extends Record<string, any>>(payload: T): T {
	return Object.fromEntries(
		Object.entries(payload).filter(([_, value]) => value !== undefined)
	) as T;
}

export {
	createNewDesign,
	updateDesing,
	getUnselectedPanels,
};
