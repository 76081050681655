import React, { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { toggleHorizontalState } from 'store/slices/AddPanelModalSlice/addPanelModalSlice';
import { ORIENTATION } from 'store/slices/AddPanelModalSlice/types';
import { PanelOrientationWrap, LabelOrientation } from './styles';

export default function SwitchOrientation() {
	const { orientation } = useSelector((state: RootState) => state.addPanelModal.data);
	const dispatch = useDispatch<AppDispatch>();


	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const changedVal = e.target.value;
		switch (changedVal) {
		case ORIENTATION.DEFAULT:
			dispatch(toggleHorizontalState({ value: changedVal }));
			break;
		case ORIENTATION.LANDSCAPE:
			dispatch(toggleHorizontalState({ value: changedVal }));
			break;
		case ORIENTATION.PORTRAIT:
			dispatch(toggleHorizontalState({ value: changedVal }));
			break;
		}
	};
	return (
		<PanelOrientationWrap>
			<LabelOrientation
				className={orientation == ORIENTATION.DEFAULT ? 'active' : ''}
				style={{
					background: '#fff',
					border: '0px',
					borderRight: '1px solid #E4E4E4',
					borderLeft: '0px',
					color: '#28373E',
					fontSize: '0.938rem',
					padding: '0px .78rem',
					height: '38px',
					width: 'auto',
					borderRadius: '0px',
					minWidth: '40px',
				}}
				id="orientation-default"
				title="Default"
			>
				<input
					type="radio"
					value={ORIENTATION.DEFAULT}
					checked={orientation == ORIENTATION.DEFAULT}
					onChange={handleChange}
				/>
				<svg width="22" height="21" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="1" y="3.49146" width="9.9435" height="17.8983" stroke={orientation == ORIENTATION.DEFAULT ? '#003CFF' : '#64686A'} strokeWidth="1.59096" />
					<rect x="1" y="3.49146" width="9.9435" height="17.8983" stroke={orientation == ORIENTATION.DEFAULT ? '#003CFF' : '#64686A'} strokeWidth="1.59096" />
					<path d="M13.2119 11.5085L23 11.5085L23 21.452L13.2119 21.452" stroke={orientation == ORIENTATION.DEFAULT ? '#003CFF' : '#64686A'} strokeWidth="1.59096" strokeLinecap="round" />
					<path d="M13.2119 11.5085L23 11.5085L23 21.452L13.2119 21.452" stroke={orientation == ORIENTATION.DEFAULT ? '#003CFF' : '#64686A'} strokeWidth="1.59096" strokeLinecap="round" />
					<path d="M21.5 9.5C21.5 4 19 3.02542 13.3984 3.02542M21.5 9.5L23 8M21.5 9.5L20 8.5M13.3984 3.02542L14.6103 1.5M13.3984 3.02542L14.6103 4.70339" stroke={orientation == ORIENTATION.DEFAULT ? '#003CFF' : '#64686A'} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M21.5 9.5C21.5 4 19 3.02542 13.3984 3.02542M21.5 9.5L23 8M21.5 9.5L20 8.5M13.3984 3.02542L14.6103 1.5M13.3984 3.02542L14.6103 4.70339" stroke={orientation == ORIENTATION.DEFAULT ? '#003CFF' : '#64686A'} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
			</LabelOrientation>
			<LabelOrientation
				className={ORIENTATION.LANDSCAPE ? 'active' : ''}
				style={{
					background: '#fff',
					border: '0px',
					borderRight: '1px solid #E4E4E4',
					borderLeft: '0px',
					color: '#28373E',
					fontSize: '0.938rem',
					padding: '0px .78rem',
					height: '38px',
					width: 'auto',
					borderRadius: '0px',
					minWidth: '40px',
				}}
				id="orientation-landscape"
				// onClick={ORIENTATION.DEFAULT}
				title="Landscape"
			>
				<svg width="19" height="17" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M18.6233 15.5C16.6233 15.5 11.125 15.4999 2 15.4999M18.6233 15.5L17.1233 17M18.6233 15.5L17.1233 13.9999M2 15.4999L3.49998 13.9999M2 15.4999L3.49998 16.9999" stroke={orientation == ORIENTATION.LANDSCAPE ? '#003CFF' : '#64686A'}  strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
					<rect x="19" y="1" width="10" height="18" transform="rotate(90 19 1)" stroke={orientation == ORIENTATION.LANDSCAPE ? '#003CFF' : '#64686A'} strokeWidth="1.6" />
				</svg>
				<input
					type="radio"
					value={ORIENTATION.LANDSCAPE}
					checked={orientation == ORIENTATION.LANDSCAPE}
					onChange={handleChange}
				/>
			</LabelOrientation>
			<LabelOrientation className={orientation == ORIENTATION.PORTRAIT ? 'active' : ''}
				style={{
					background: '#fff',
					border: '0px',
					borderRight: '1px solid #E4E4E4',
					borderLeft: '0px',
					color: '#28373E',
					fontSize: '0.938rem',
					padding: '0px .78rem',
					height: '38px',
					width: 'auto',
					borderRadius: '0px',
					minWidth: '40px',
				}}
				title="Portrait"
				id="orientation-portrait">
				<svg width="19" height="19" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="8" y="1" width="11" height="18" stroke={orientation == ORIENTATION.PORTRAIT ? '#003CFF' : '#64686A'} strokeWidth="1.6" />
					<path d="M2.50005 18.6233C2.49997 16.6233 2.5001 11.125 2.50012 2M2.50005 18.6233L1 17.1233M2.50005 
					18.6233L4.0001 17.1233M2.50012 2L4.0001 3.49998M2.50012 2L1.00014 3.49998" 
					stroke={orientation == ORIENTATION.PORTRAIT ? '#003CFF' : '#64686A'} 
					strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
				<input
					type="radio"
					value={ORIENTATION.PORTRAIT}
					checked={orientation == ORIENTATION.PORTRAIT}
					onChange={handleChange}
				/>
			</LabelOrientation>
		</PanelOrientationWrap>
	);
}