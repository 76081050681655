import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { NewlyAddedPanel, CurrentMovablePanel, DeletedPanel, PanelSlice } from './types';
import { initializeState } from './util';

const initialState: { data: PanelSlice } = {
	data: initializeState()
};

function resetUnfinalizedState(state: PanelSlice) {
	state.enableAddPanel = false;
	state.newlyAddedPanels = [];
	state.enablePanelMove = false; 
	state.currentMovablePanels = [];
	state.deleteIndividualPanelMode = false;
	state.deletedPanels = [];
	state.currentIndex = 0;
	state.panelHistory = [];
}

export const PanelStateSlice = createSlice({
	name: 'PanelSlice',
	initialState,
	reducers: {
		setAddPanel: (state, action: PayloadAction<{ shouldEnable: boolean }>) => {
			state.data.enableAddPanel = action.payload.shouldEnable;
			if (!action.payload.shouldEnable) {
				state.data.newlyAddedPanels = [];
				state.data.activePanelMode = false;
				state.data.currentIndex = 0;
				state.data.panelHistory = [];
			} else {
				state.data.activePanelMode = true;
			}
		},
		setNewlyAddedPanel: (state, action: PayloadAction<{ addedPanel: NewlyAddedPanel }>) => {
			const currentPanels = [...state.data.newlyAddedPanels, action.payload.addedPanel];
			
			if (state.data.currentIndex === 0 && state.data.panelHistory.length === 0) {
				state.data.panelHistory.push({ newlyAddedPanels: [], deletedPanels: [] });
			}
			
			const newState = { newlyAddedPanels: currentPanels, deletedPanels: state.data.deletedPanels };
			
			if (state.data.panelHistory[state.data.currentIndex]?.newlyAddedPanels !== currentPanels ||
				state.data.panelHistory[state.data.currentIndex]?.deletedPanels !== state.data.deletedPanels) {
				state.data.panelHistory = [
					...state.data.panelHistory.slice(0, state.data.currentIndex + 1),
					newState,
				];
				state.data.currentIndex = state.data.panelHistory.length - 1;
			}
			state.data.newlyAddedPanels = currentPanels;
		},
		setPanelMove: (state, action: PayloadAction<{ shouldEnablePanelMove: boolean }>) => {
			state.data.enablePanelMove = action.payload.shouldEnablePanelMove;
			if (!action.payload.shouldEnablePanelMove) {
				state.data.currentMovablePanels = [];
				state.data.activePanelMode = false;
			} else {
				state.data.activePanelMode = true;
			}
		},
		setCurrentMovablePanels: (state, action: PayloadAction<{ currentMovablePanel: CurrentMovablePanel }>) => {
			const newPanels = state.data.currentMovablePanels.filter(eachPanel => eachPanel.panelId !== action.payload.currentMovablePanel.panelId);
			state.data.currentMovablePanels = [...newPanels, action.payload.currentMovablePanel];
		},
		setDeletedPanels: (state, action: PayloadAction<{ data: DeletedPanel | null }>) => {
			const currentDeletedPanels = !action.payload.data ? [] : [...state.data.deletedPanels, action.payload.data];
			
			if (state.data.currentIndex === 0 && state.data.panelHistory.length === 0) {
				state.data.panelHistory.push({ newlyAddedPanels: [], deletedPanels: [] });
			}
			
			const newState = { newlyAddedPanels: state.data.newlyAddedPanels, deletedPanels: currentDeletedPanels };
			
			if (state.data.panelHistory[state.data.currentIndex]?.newlyAddedPanels !== state.data.newlyAddedPanels ||
				state.data.panelHistory[state.data.currentIndex]?.deletedPanels !== currentDeletedPanels) {
					
				state.data.panelHistory = [
					...state.data.panelHistory.slice(0, state.data.currentIndex + 1),
					newState,
				];
				state.data.currentIndex = state.data.panelHistory.length - 1;
			}
	
			state.data.deletedPanels = currentDeletedPanels;
		},
		setDeleteIndividualPanelsMode: (state, action: PayloadAction<{ enabled: boolean }>) => {
			state.data.deleteIndividualPanelMode = action.payload.enabled;
			if (!action.payload.enabled) {
				state.data.deletedPanels = [];
				state.data.activePanelMode = false;
				state.data.currentIndex = 0;
				state.data.panelHistory = [];
			} else {
				state.data.activePanelMode = true;
			}
		},
		undoPanelState: (state) => {
			if (state.data.currentIndex > 0) {
				state.data.currentIndex -= 1;
				const previousState = state.data.panelHistory[state.data.currentIndex];
				state.data.newlyAddedPanels = previousState.newlyAddedPanels;
				state.data.deletedPanels = previousState.deletedPanels;
			}
		},
		redoPanelState: (state) => {
			if (state.data.currentIndex < state.data.panelHistory.length - 1) {
				state.data.currentIndex += 1;
				const nextState = state.data.panelHistory[state.data.currentIndex];
				state.data.newlyAddedPanels = nextState.newlyAddedPanels;
				state.data.deletedPanels = nextState.deletedPanels;
			}
		},
		togglePanelModal: (state, action: PayloadAction<{targetModal:string, val:boolean}>) => {
			switch(action.payload.targetModal) {
			case 'CANCEL_ADD_PANEL':
				state.data.cancelAddPanelModal = action.payload.val;
				break;
			case 'CANCEL_MOVE_PANEL':
				state.data.cancelMovePanelModal = action.payload.val;
				break;
			case 'CANCEL_DELETE_PANEL':
				state.data.cancelDeletePanelModal = action.payload.val;
				break;
			}
		}
	}
});

export const {
	setAddPanel, setNewlyAddedPanel,
	setPanelMove, setCurrentMovablePanels, 
	setDeletedPanels, setDeleteIndividualPanelsMode,
	undoPanelState, redoPanelState, togglePanelModal
} = PanelStateSlice.actions;

export default PanelStateSlice.reducer;