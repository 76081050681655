import Button from 'components/Basic/Button';
import { checkPanelDrawerHasUnfinalizedFacets, deleteAllFacetsFromPanelDrawer } from 'components/tool/util';
import { useRefs } from 'contexts/RefContext';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setAddPanel, setDeleteIndividualPanelsMode, setPanelMove, togglePanelModal } from 'store/slices/PanelSlice';
import { setDrawModeState, showDeleteObstructionsPopUp, toggleDeleteObstructionsMode } from 'store/slices/ToolSlice';

export default function CancelBtn() {
	const { enableAddPanel, deleteIndividualPanelMode, newlyAddedPanels,
		deletedPanels, enablePanelMove, currentMovablePanels } = useSelector((state: RootState) => state.panelSlice.data);
	const { drawModeEnabled, deleteObstructionsMode, deletedObstructionIDs } = useSelector((state: RootState) => state.toolNewPostions.data);
	const showCancelModalIfDesignHasModificaton= !!newlyAddedPanels.length || !!deletedPanels.length || !!currentMovablePanels.length;


	const dispatch = useDispatch();
	const {konvaRef} = useRefs();

	function getTargetModal() {
		if (enableAddPanel) return 'CANCEL_ADD_PANEL';
		if (deleteIndividualPanelMode) return 'CANCEL_DELETE_PANEL';
		if (enablePanelMove) return 'CANCEL_MOVE_PANEL';
		return '';
	}

	function handleClick() {
		if (showCancelModalIfDesignHasModificaton) {
			const targetModal = getTargetModal();
			dispatch(togglePanelModal({ targetModal, val: true }));
			return;
		}

		if(drawModeEnabled) {
			if (drawModeEnabled && konvaRef?.current && checkPanelDrawerHasUnfinalizedFacets(konvaRef.current)) {
				deleteAllFacetsFromPanelDrawer(konvaRef.current);
			}
			dispatch(setDrawModeState({ enabled: false }));
		} else if(deleteObstructionsMode) {
			dispatch(toggleDeleteObstructionsMode({ enable: false }));
			if(deletedObstructionIDs.length > 0) {
				dispatch(showDeleteObstructionsPopUp({ enable: true }));
			}
		} else if (enableAddPanel) {
			dispatch(setAddPanel({ shouldEnable: false }));
		} else if (deleteIndividualPanelMode) {
			dispatch(setDeleteIndividualPanelsMode({ enabled: false }));
		} else if (enablePanelMove) {
			dispatch(setPanelMove({ shouldEnablePanelMove: false }));
		}

	}
	return (
		<Button
			onClick={handleClick}
			className="cancel-btn"
			style={{
				background: 'rgb(242 242 242)',
				border: '1px solid #e4e4e4',
				color: '#64686A',
				fontSize: '0.875rem',
				padding: '0px .35rem',
				height: '36px',
				minWidth: '36.5px',
				borderRadius: '20px',
				boxShadow: 'none',
			}}
		>
			<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 1L12 12M12 1L1 12" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" />
			</svg>
		</Button>
	);

}