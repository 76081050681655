import Button from 'components/Basic/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { redoEditFacetState, resetDeletedObstructionIDs, showDeleteObstructionsPopUp, toggleDeleteObstructionsMode, undoEditFacetState } from 'store/slices/ToolSlice';
import { ReactComponent as CancelSvg } from '../../../../../../assets/svg/cancel.svg';
import { useEffect, useState } from 'react';
import ConfirmationModal from 'components/Basic/Modal/Confirmation';
import UndoButton from './undoBtn';
import RedoButton from './redoBtn';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';

export default function DeleteObstructionModeToggle() {

	const { roofIndexes, deleteObstructionsMode, deletedObstructionIDs, 
		currentActiveFacetEditMode, facetHistory: { history }, deleteObstructionsPopUp } =
		useSelector((state: RootState) => state.toolNewPostions.data);
	const isEditPaelMOdeActive = useSelector((state: RootState) => state.panelSlice.data.activePanelMode);
	const dispatch = useDispatch();
	const [openConfirmationModal, toggleConfirmationModal] = useState<boolean>(false);
	const userHasDeletedObstructions = !!deletedObstructionIDs?.length;
	const queriesRunningInBackGround = useQueriesRunningInBackGround();
	const disableDeleteObstructionMode = isEditPaelMOdeActive || !!queriesRunningInBackGround ||
		(currentActiveFacetEditMode && currentActiveFacetEditMode != 'DELETE_OBSTRUCTION_MODE') || !!roofIndexes?.length;
	const showDeleteOBstructionUndoRedo = (deleteObstructionsMode && (!!deletedObstructionIDs?.length || !!history?.length));

	useEffect(() => {
		const handleKeyDown = (e:KeyboardEvent) => {
			const key = e.key.toLowerCase();
			if ((e.ctrlKey || e.metaKey) && key === 'z') {
				e.preventDefault();
				dispatch(undoEditFacetState());
			} else if ((e.ctrlKey || e.metaKey) && key === 'y') {
				e.preventDefault();
				dispatch(redoEditFacetState());
			}
		};

		window.addEventListener('keydown', handleKeyDown);
		return () => window.removeEventListener('keydown', handleKeyDown);
	}, [dispatch]);

	function handleClick() {
		if (userHasDeletedObstructions && deleteObstructionsMode) {
			dispatch(showDeleteObstructionsPopUp({ enable: true }));
			return;
		}
		dispatch(toggleDeleteObstructionsMode({ enable: !deleteObstructionsMode }));
	}

	function handleConfirmation() {
		dispatch(resetDeletedObstructionIDs());
		dispatch(toggleDeleteObstructionsMode({ enable: false }));
		dispatch(showDeleteObstructionsPopUp({ enable: false }));
	}

	return (
		<>
			<Button style={{
				background: `${deleteObstructionsMode ? 'rgb(from #003CFF r g b / 10%)' : '#fff'}`,
				border: '0px',
				borderRight: '1px solid #e4e4e4',
				borderLeft: '0px',
				color: `${deleteObstructionsMode ? '#003CFF' : '#28373E'}`,
				fontSize: '0.938rem',
				padding: '0px 8px',
				height: '36px',
				minWidth: '40px',
				width: 'auto',
				borderRadius: '0px',
				transition: 'all 2s ease',

			}}
			id="delete-obstruction-mode"
			tooltipContent='Delete Obstructions'
			onClick={handleClick}
			disabled={disableDeleteObstructionMode}
			>
				<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M10.1058 1V0.2V1ZM4.2138 1V1.8V1ZM1 4.34924H0.2H1ZM1 12.3631H0.2H1ZM4.46886 1V0.2V1ZM14.0664 10.5976L14.1984 11.3866L14.0664 10.5976ZM10.7277 13.5178L11.4919 13.7543L10.7277 13.5178ZM16.6099 10.9595L16.2614 11.6796L16.6099 10.9595ZM13.7067 
					12.5753C13.3943 12.2629 12.8877 12.2629 12.5753 12.5753C12.2629 12.8877 12.2629 13.3943 12.5753 13.7067L13.7067 12.5753ZM15.6106 16.7419C15.923 17.0544 16.4295 17.0544 16.7419 16.7419C17.0544 16.4295 17.0544 15.923 16.7419 15.6106L15.6106 16.7419ZM12.5753 
					15.6106C12.2629 15.923 12.2629 16.4295 12.5753 16.7419C12.8877 17.0544 13.3943 17.0544 13.7067 16.7419L12.5753 15.6106ZM16.7419 13.7067C17.0544 13.3943 17.0544 12.8877 16.7419 12.5753C16.4295 12.2629 15.923 12.2629 15.6106 12.5753L16.7419 13.7067ZM15.8099 
					3.98424V6.96848H17.4099V3.98424H15.8099ZM10.1058 1.8H13.6256V0.2H10.1058V1.8ZM15.8099 6.96848V7.50411H17.4099V6.96848H15.8099ZM17.1756 6.93843L10.6714 0.434315L9.54007 1.56569L16.0442 8.0698L17.1756 6.93843ZM4 1.8H4.2138V0.2H4V1.8ZM1.8 4.34924V4H0.2V4.34924H1.8ZM1.8 
					10.1058V4.34924H0.2V10.1058H1.8ZM8.0698 16.0442L1.56569 9.54007L0.434315 10.6714L6.93843 17.1756L8.0698 16.0442ZM7.50411 15.8099H5.2468V17.4099H7.50411V15.8099ZM5.2468 15.8099H4V17.4099H5.2468V15.8099ZM1.8 13.6099V12.3631H0.2V13.6099H1.8ZM1.8 12.3631V10.1058H0.2V12.3631H1.8ZM4.2138 
					1.8H4.46886V0.2H4.2138V1.8ZM4.46886 1.8H10.1058V0.2H4.46886V1.8ZM18.2 14.7697C18.2 16.6642 16.6642 18.2 14.7697 18.2V19.8C17.5479 19.8 19.8 17.5479 19.8 14.7697H18.2ZM14.7697 18.2C12.8752 18.2 11.3394 16.6642 11.3394 14.7697H9.73937C9.73937 17.5479 11.9915 19.8 14.7697 19.8V18.2ZM14.1984 
					11.3866C14.3837 11.3556 14.5745 11.3394 14.7697 11.3394V9.73937C14.4857 9.73937 14.2066 9.76298 13.9344 9.80853L14.1984 11.3866ZM14.6321 10.0319L5.03455 0.434315L3.90317 1.56569L13.5007 11.1632L14.6321 10.0319ZM11.3394 14.7697C11.3394 14.4149 11.393 14.0742 11.4919 13.7543L9.96339 13.2814C9.81758 
					13.7527 9.73937 14.2528 9.73937 14.7697H11.3394ZM11.4919 13.7543C11.8701 12.5318 12.9148 11.6014 14.1984 11.3866L13.9344 9.80853C12.0468 10.1244 10.5181 11.4884 9.96339 13.2814L11.4919 13.7543ZM11.2764 12.9357L1.54871 3.76707L0.451291 4.93141L10.1789 14.1L11.2764 12.9357ZM10.7562 15.8099H7.50411V17.4099H10.7562V15.8099ZM14.7697 
					11.3394C15.3059 11.3394 15.8113 11.4619 16.2614 11.6796L16.9583 10.2394C16.2957 9.91874 15.5525 9.73937 14.7697 9.73937V11.3394ZM16.2614 11.6796C17.4104 12.2355 18.2 13.4112 18.2 14.7697H19.8C19.8 12.7746 18.6385 11.0523 16.9583 10.2394L16.2614 11.6796ZM15.8099 7.50411V10.9595H17.4099V7.50411H15.8099ZM12.5753 13.7067L14.093 
					15.2243L15.2243 14.093L13.7067 12.5753L12.5753 13.7067ZM14.093 15.2243L15.6106 16.7419L16.7419 15.6106L15.2243 14.093L14.093 15.2243ZM13.7067 16.7419L15.2243 15.2243L14.093 14.093L12.5753 15.6106L13.7067 16.7419ZM15.6106 12.5753L14.093 14.093L15.2243 15.2243L16.7419 13.7067L15.6106 12.5753ZM4 15.8099C2.78497 15.8099 1.8 14.8249 
					1.8 13.6099H0.2C0.2 15.7086 1.90132 17.4099 4 17.4099V15.8099ZM17.4099 3.98424C17.4099 1.89426 15.7156 0.2 13.6256 0.2V1.8C14.832 1.8 15.8099 2.77792 15.8099 3.98424H17.4099ZM4 0.2C1.90132 0.2 0.2 1.90132 0.2 4H1.8C1.8 2.78497 2.78497 1.8 4 1.8V0.2Z" 
					fill={deleteObstructionsMode ? '#003CFF' :'#64686A'} />
				</svg>
				{deleteObstructionsMode && (
					<label className='buttonLabel' 
						style={{
							color: `${deleteObstructionsMode ? '#003CFF' :'#64686A'}`,
						}}>
						Delete Obstructions
						<span className='buttonIcon'>
							<CancelSvg />
						</span>
					</label>
				)}
			</Button>

			{showDeleteOBstructionUndoRedo && <> <UndoButton /> <RedoButton /> </>}

			<ConfirmationModal
				open={deleteObstructionsPopUp}
				onCancel={() => {
					dispatch(toggleDeleteObstructionsMode({ enable: false }));
					dispatch(showDeleteObstructionsPopUp({enable:false}));
				}}
				onConfirm={handleConfirmation}
				message='Are you sure you want to discard the deletion of the obstructions?' />
		</>
	);
}