import { getAbsolutePoints } from 'components/DisplayEnergy/util';
import Konva from 'konva';
import { getAllKonvaGroups, normalizeAngle } from '../util';

export function getAbsolutePointsOfTranslatedPanels(stage: Konva.Stage, roofIndexes: string[], 
	allRoofSegs: RasterRoofSegment[],currentScale: Vector2d
) {
	try{
		const groups = getAllKonvaGroups(stage)
			.filter(g => roofIndexes.includes(g.attrs.name.split('>')[2])) as Konva.Group[];
		const newPos: {
		segmentId: number,
		azimuth: number,
		previousAzimuthDegrees: number,
		pitch: number;
		translatedPos: {
			[id: string]: {
				pos: number[][]
			}
		}
	}[] = [];

		//TODO: key in payload for transformed hull coords

		const stagePosition= stage.position();
		groups?.forEach(g => {
			const translatedPos: typeof newPos[0]['translatedPos'] = {};
			let roofSegmentTranslatedPos:number[][]= []; // tranformed hull coords
			const segmentId = g.attrs.name.split('>')[2];
			console.log(g.children, g);
			g.children?.forEach((child) => {
				
				if (child.className === 'Line' ) {
					const newPoints = (getAbsolutePoints(child as Konva.Line, currentScale, stagePosition) as number[]);
					if(child.attrs.id === segmentId){
						// traslated pos for roof segments
						roofSegmentTranslatedPos= transformFlatArrayToCoordinateTypeArray(newPoints);
					}else{
						//translated postion for panels 
						translatedPos[child.attrs.id] = {
							pos: [...transformFlatArrayToCoordinateTypeArray(newPoints)]
						};
					}
					
				}
			});
			console.log(roofSegmentTranslatedPos);//test
			const roofSegment = allRoofSegs.find(rs=>rs.id === segmentId);
			if(!roofSegment) return [];
			newPos.push({
				segmentId,
				azimuth: normalizeAngle( g.rotation()-roofSegment.azimuthDegrees) ,
				previousAzimuthDegrees: roofSegment.azimuthDegrees,
				translatedPos,
				pitch: roofSegment.pitchDegrees
			});

		});
		return newPos;
	}catch(e){
		throw new Error('E_WHILE_GETTING_TRANSLATION_FOR_SEGMENT');
	}
}

export function resetTraslateFacets(stage: Konva.Stage, roofIndexes: string[]) {
	const groups = getAllKonvaGroups(stage)
		.filter(g => roofIndexes.includes(g.attrs.name.split('>')[2])) as Konva.Group[];

	groups?.forEach(g => {
		g.setPosition({ x: 0, y: 0 });
	});
}

export function transformFlatArrayToCoordinateTypeArray(list: number[]): number[][] {
	const transformedArray = [];
	for (let i = 0; i <= list.length - 2; i += 2) {
		transformedArray.push([list[i], list[i + 1]]);
	}
	return transformedArray;
}

