import styled from 'styled-components';

export const ModalPopup = styled.div`
display: flex;
gap:2rem;
width: 100%;
height: 100%;
flex-direction: column;
justify-content: center;	
padding: 14px 14px 8px;
max-height: 280px;
overflow-y: auto;
box-sizing: border-box;
`;

export const ModalcontentInfo = styled.div`
	color: #2E384D;
	font-size: 1.25em;
	text-align:center;
	font-weight:600
`;
export const Modalbutton = styled.button`
background: var(--primary);
appearance: none;
border: 1px solid var(--primary);
padding: 10px 24px;
border-radius: 3px;
color: #fff;
font-weight: 600;
font-size: 1rem;
cursor: pointer;
text-align: center;
max-width: 120px;
`;

export const ModalbuttonNo = styled.button`
background: #bdbdbd;
appearance: none;
border: 1px solid #bdbdbd;
padding: 10px 22px;
border-radius: 3px;
color: #5d5d5d;
font-weight: 600;
font-size: 1rem;
cursor: pointer;
text-align: center;
max-width: 120px;
margin: 0;
margin-right: 15px;
`;
export const ModelContent = styled.div`
display: flex;
gap:2rem;
flex-direction: row;
align-items:flex-end;
justify-content: center;	
@media (max-width:500px){
					gap:1em;
			}
`;

export const ModelLabelValueWrapper = styled.div`
display: flex;
gap:1rem;
flex-direction: column;
justify-content: center;	
align-items:center
`;
export const ModalbuttonWrap = styled.div`
		display: flex;
    justify-content: center;
		margin: 10px 0px
`;
