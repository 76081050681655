/* eslint-disable react/prop-types */
import Select, { components, OptionProps } from 'react-select';
import { SelectorPanelOption, RowFlex, ColFlex } from './style';
import { Id, toast } from 'react-toastify';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { changePanel } from 'store/slices/RoofDataSlice/roofDataSlice';
import useScreenDims from 'hooks/useScreenDims';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { setThreeDState } from 'store/slices/ToolSlice';

type PanelDetailsSelector = (PanelDetails & { label: string, value: string });

type Props = {
	panelDetails: PanelDetails[],
	panelFinancingInfo: panelFinancingInfo[],
	panelKey: string;
}

function PanelCard(props: OptionProps<PanelDetailsSelector>) {
	const { name, manufacturer, wattage, financialKeys } = props.data;
	return (
		<components.Option {...props} >
			<SelectorPanelOption>
				<RowFlex>
					<ColFlex>
						<h3>{manufacturer} {financialKeys && <span><b>{financialKeys}</b></span>}</h3>
						<span>{name}</span>
					</ColFlex>
					<h4>{wattage * 1000}w </h4>
				</RowFlex>
			</SelectorPanelOption>
		</components.Option >
	);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SingleValue = (props: any) => {
	const { label, financialKeys } = props.getValue()[0];

	return (
		<components.SingleValue {...props}>
			<span>{label}</span> {financialKeys && <span><b>{financialKeys}</b></span>}
		</components.SingleValue>
	);
};

export default function PanelSelector(props: Props) {
	const { panelDetails, panelKey, panelFinancingInfo } = props;
	const dispatch = useDispatch<AppDispatch>();
	const { width } = useScreenDims();
	const queriesRunningInBackGround = useQueriesRunningInBackGround();
	const { configuringNewPanel, error, data: roofData } = useSelector((state: RootState) => state.roofData);
	const { threeDModelEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { aiToolPanelKeys } = useSelector((state: RootState) => state.QueryParams.data);
	const previouseToastId = useRef<Id>();

	const disable3D = () => {
		dispatch(setThreeDState({ enabled: false }));
	};
	const panelKeysWithLabel = useMemo(() => panelDetails.filter(p => !aiToolPanelKeys?.length || aiToolPanelKeys?.includes(p.key))
		.map(p => {
			let financialKeys = '';
			if (panelFinancingInfo?.length) {
				const foundKey = panelFinancingInfo.find((val) => val.panelKey === p.key);
				if (foundKey)
					financialKeys = '(' + foundKey.financialKeys.join('-') + ')';
			}
			return {
				...p,
				label: `${p.manufacturer}-${p.name}-${p.wattage}`,
				financialKeys,
				value: p.key,
			};
		}) as unknown as PanelDetailsSelector[], [panelDetails]);
	const panelRef = useRef<string>(panelKey);

	const showErrorToastOnChnagePanel = useCallback(function () {
		if (!error || previouseToastId?.current) return;
		const errorCode = error.error;
		let msg = error.message;
		if (errorCode === 'E_INVALID_PANEL')
			msg = `${panelRef.current} panel is not present`;
		previouseToastId.current = toast.error(
			msg,
			{
				onClose: () => {
					previouseToastId.current = undefined;
				},
			}
		);
	}, [error]);

	useEffect(() => {
		showErrorToastOnChnagePanel();
	}, [error]);

	return (
		<>
			<Select
				className="react-select-wrap"
				options={panelKeysWithLabel}
				components={{ Option: PanelCard, SingleValue }}
				menuPlacement={width > 935 ? 'bottom' : 'top'}
				styles={{
					menuList: base => ({
						...base,
						maxHeight: '200px',
						width: '100%',
					}),
					option: (base, props) => {

						let backgroundColor = base.backgroundColor;
						if (props.isFocused)
							backgroundColor = '#F2F6FD';
						if (props.isSelected)
							backgroundColor = '#E2ECFF';
						return {
							margin: 0,
							padding: 0,
							backgroundColor
						};
					},
					loadingIndicator: base => ({
						...base,
						color: '#2E384D',
					}),
					// eslint-disable-next-line @typescript-eslint/no-unused-vars
					indicatorSeparator: base => ({

					}),
					container: base => ({
						...base,
						marginTop: '0.3em',
						width: '100%'
					})
				}}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				onChange={(data: any) => {
					if (panelRef.current === data.value) return;
					panelRef.current = data.value as string;
					if (threeDModelEnabled)
						disable3D();
					dispatch(
						changePanel(
							{ uuid: roofData.uuid, panelKey: data.value, source: roofData.imagerySource }
						));
				}}
				isLoading={configuringNewPanel}
				defaultValue={panelKeysWithLabel.find(p => p.value.toUpperCase() === panelKey)}
				isDisabled={configuringNewPanel || queriesRunningInBackGround}
				isSearchable={false}
			/>
		</>
	);

}