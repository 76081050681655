import Button from 'components/Basic/Button';
import { ConfirmationPopup, ModalContainer, ModalContent, ModalFooter, ModalHeader } from './styles';

type Props = {
	open: boolean;
	onConfirm: () => void;
	onCancel: () => void;
}

export default function ConfirmationModal({ open, onConfirm, onCancel }: Props) {

	return (
		<ConfirmationPopup
			open={open}
			closeOnDocumentClick={false}
			onClose={onCancel}
		>
			<ModalContainer className='confirmation-modal'>
				<ModalHeader className='confirmation-modal-head'>
					Confirm Action
				</ModalHeader>
				<ModalContent className='confirmation-modal-content'>
					Applying these changes may alter the current design layout. Are you sure you want to continue?
				</ModalContent>

				<ModalFooter className='confirmation-modal-footer'>
					<Button onClick={() => onCancel()} style={{ color: 'var(--label)', background:'transparent' }}> No</Button>
					<Button onClick={() => onConfirm()} style={{borderColor: 'var(--primary)'}}> Yes  </Button>
				</ModalFooter>
			</ModalContainer>

		</ConfirmationPopup>
	);
}