import Button from 'components/Basic/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { redoEditFacetState } from 'store/slices/ToolSlice';

export default function RedoButton() {
	const dispatch = useDispatch();
	const { currentIndex, history } = useSelector((state: RootState) => state.toolNewPostions.data.facetHistory);
	const shouldDisable = (currentIndex >= history.length - 1);

	return (
		<>
			<Button
				className='active'
				id="redo"
				style={{
					// background: `${shouldDisable ? '#fff' : 'rgb(from #003CFF r g b / 10%)'}`,
					background: '#fff',
					border: '0px',
					borderLeft: '1px solid #e4e4e4',
					borderRight: '0px',
					color: '#003CFF',
					fontSize: '14px',
					padding: '0px 10px 0 8px',
					height: '36px',
					minWidth: '40px',
					borderRadius: '0px',
				}}
				disabled={shouldDisable}
				onClick={()=>dispatch(redoEditFacetState())}
				tooltipContent='Redo'>
				{/* <svg width="14" height="16" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M19.6296 17.3987C17.8772 20.2562 14.7634 22.1569 11.2142 22.1569C5.73672 22.1569 
					1.29639 17.63 1.29639 12.0457C1.29639 6.46149 5.73672 1.93457 11.2142 1.93457C15.2358 
					1.93457 18.6983 4.3749 20.2548 7.88231M20.2548 7.88231V2.52934M20.2548 7.88231H15.2979"
					stroke='#003CFF'
					strokeWidth="1.90964"
					strokeLinecap="round"
					strokeLinejoin="round" />
				</svg> */}
				{/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1 5.15385H12.0769C13.913 5.15385 15.6739 5.88324 16.9723 7.18157C18.2706 8.4799 19 10.2408 19 12.0769C19 
					13.913 18.2706 15.6739 16.9723 16.9723C15.6739 18.2706 13.913 19 12.0769 19H6.53846M1 5.15385L5.15385 1M1 5.15385L5.15385 9.30769" 
					stroke="#003CFF" 
					strokeWidth="1.6" 
					strokeLinecap="round" 
					strokeLinejoin="round" />
					</svg>  */}
				<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M19 5.15385H7.92308C6.08696 5.15385 4.32605 5.88324 3.02772 7.18157C1.72939 8.4799 1 10.2408 1 12.0769C1 13.913 1.72939 15.6739 3.02772 
					16.9723C4.32605 18.2706 6.08696 19 7.92308 19H13.4615M19 5.15385L14.8462 1M19 5.15385L14.8462 9.30769" 
					stroke={shouldDisable ?  '#64686A' : '#003CFF'} 
					strokeWidth="1.6" 
					strokeLinecap="round" 
					strokeLinejoin="round"/>
				</svg>
				

			</Button>
		</>
	);
}
