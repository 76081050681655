import Button from 'components/Basic/Button';
import CustomTooltip from 'components/Basic/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { setPanelMove, togglePanelModal } from 'store/slices/PanelSlice';
import CancelMovePanelModal from './CancelMovePanelModal';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';

export default function MovePanelButton() {
	const dispatch = useDispatch<AppDispatch>();
	const { activePanelMode, enablePanelMove, currentMovablePanels } = useSelector((state: RootState) => state.panelSlice.data);
	const { drawModeEnabled, hadTransition, currentActiveFacetEditMode } = useSelector((state: RootState) => state.toolNewPostions.data);
	const queriesRunningInBackground = useQueriesRunningInBackGround();

	const shouldDisable = queriesRunningInBackground || !!currentActiveFacetEditMode || 
	activePanelMode && !enablePanelMove || drawModeEnabled || hadTransition;
	function handleClick() {
		if(enablePanelMove && currentMovablePanels.length > 0){
			dispatch(togglePanelModal({targetModal: 'CANCEL_MOVE_PANEL', val:true}));
			return;
		}
	
		dispatch(setPanelMove({ shouldEnablePanelMove: !enablePanelMove}));
	}

	function handleCancelCLick(event: React.MouseEvent<HTMLButtonElement>){
		event.stopPropagation();
		if(currentMovablePanels.length > 0){
			dispatch(togglePanelModal({targetModal: 'CANCEL_MOVE_PANEL', val:true}));
			return;
		}
		dispatch(setPanelMove({ shouldEnablePanelMove: false }));
	}

	return (
		<>
			<Button style={{
				background: `${enablePanelMove ? 'rgb(from #003CFF r g b / 10%)' : '#fff'}`,
				border: '0px',
				borderRight: '1px solid #e9e9e9',
				borderLeft: '0px',
				color: '#28373E',
				fontSize: '0.938rem',
				padding: '0px 8px',
				height: '36px',
				width: 'auto',
				minWidth: '40px',
				borderRadius: '0px'
				// height: `${(enableAddPanel || deleteIndividualPanelMode || enablePanelMove) ? '26px' : '34px'}`,
				// width: `${(enableAddPanel || deleteIndividualPanelMode || enablePanelMove) ? '26px' : '34px'}`,
				// boxShadow: `${enableAddPanel ? '1px 4px 6px rgba(100, 100, 100, 0.12)' : '0px 2.25911px 4px rgba(100, 100, 100, 0.12)'}`,
			}}
			onClick={handleClick}
			id="move-panel"
			disabled={shouldDisable}
			// title="Move Panel"
			>
				{/* <svg width="16.5" height="18" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.75661 21.0315H1.38574V1.93506H15.7081V7.90269" 
						stroke={`${enablePanelMove ? '#003CFF' : '#4A525A'}`} strokeWidth="1.90964" strokeLinejoin="round"/>
					<path d="M7.35352 16.2572H19.2888M7.35352 16.2572L9.18971 14.421M7.35352 16.2572L9.18971 
					18.0934M19.2888 16.2572L17.4526 14.421M19.2888 16.2572L17.4526 18.0934M13.3212 10.2896V22.2248M13.3212 
					10.2896L11.485 12.1257M13.3212 10.2896L15.1573 12.1257M13.3212 22.2248L11.485 20.3886M13.3212 22.2248L15.1573 20.3886" 
					stroke={`${enablePanelMove ? '#003CFF' : '#4A525A'}`} strokeWidth="1.53454" strokeLinecap="round" strokeLinejoin="round"/>
				</svg> */}
				<svg width="17.5" height="20" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M5.76112 18.5088H1V1.58032H13.6963V6.87046" stroke={`${enablePanelMove ? '#003CFF' : '#64686A'}`} strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M6.29004 14.2766H16.8703M6.29004 14.2766L7.91777 12.6489M6.29004 14.2766L7.91777 15.9043M16.8703 14.2766L15.2426 12.6489M16.8703 14.2766L15.2426 15.9043M11.5802 8.98645V19.5667M11.5802 8.98645L9.95244 10.6142M11.5802 8.98645L13.2079 10.6142M11.5802 19.5667L9.95244 17.939M11.5802 19.5667L13.2079 17.939" stroke={`${enablePanelMove ? '#003CFF' : '#64686A'}`} strokeWidth="1.36032" strokeLinecap="round" strokeLinejoin="round" />
				</svg>

				{enablePanelMove && (
					<label className='buttonLabel'
						style={{
							color: `${enablePanelMove ? '#003CFF' : '#64686A'}`,
						}}>
						Move Panel
						<span className='buttonIcon' onClick={handleCancelCLick}>
							<svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M5.71132 4.50043L8.73856 1.47233C8.82035 1.39334 8.88559 1.29884 8.93048 1.19436C8.97536 
								1.08988 8.99898 0.97751 8.99997 0.863803C9.00096 0.750096 8.97929 0.637331 8.93623 0.532087C8.89317 
								0.426843 8.82958 0.331229 8.74918 0.250823C8.66877 0.170416 8.57316 0.106829 8.46791 0.0637703C8.36267 
								0.0207117 8.2499 -0.000955751 8.1362 3.23334e-05C8.02249 0.00102042 7.91012 0.0246443 7.80564 0.0695253C7.70116 
								0.114406 7.60667 0.179646 7.52767 0.261437L4.49957 3.28868L1.47233 0.261437C1.39334 0.179646 1.29884 0.114406 
								1.19436 0.0695253C1.08988 0.0246443 0.97751 0.00102042 0.863803 3.23334e-05C0.750096 -0.000955751 0.637331 
								0.0207117 0.532087 0.0637703C0.426843 0.106829 0.331229 0.170416 0.250823 0.250823C0.170416 0.331229 0.106829 
								0.426843 0.0637703 0.532087C0.0207117 0.637331 -0.000955751 0.750096 3.23334e-05 0.863803C0.00102042 0.97751 
								0.0246443 1.08988 0.0695253 1.19436C0.114406 1.29884 0.179646 1.39334 0.261437 1.47233L3.28868 4.49957L0.261437 
								7.52767C0.179646 7.60667 0.114406 7.70116 0.0695253 7.80564C0.0246443 7.91012 0.00102042 8.02249 3.23334e-05 
								8.1362C-0.000955751 8.2499 0.0207117 8.36267 0.0637703 8.46791C0.106829 8.57316 0.170416 8.66877 0.250823 
								8.74918C0.331229 8.82958 0.426843 8.89317 0.532087 8.93623C0.637331 8.97929 0.750096 9.00096 0.863803 8.99997C0.97751 
								8.99898 1.08988 8.97536 1.19436 8.93048C1.29884 8.88559 1.39334 8.82035 1.47233 8.73856L4.49957 5.71132L7.52767 
								8.73856C7.60667 8.82035 7.70116 8.88559 7.80564 8.93048C7.91012 8.97536 8.02249 8.99898 8.1362 8.99997C8.2499 
								9.00096 8.36267 8.97929 8.46791 8.93623C8.57316 8.89317 8.66877 8.82958 8.74918 8.74918C8.82958 8.66877 8.89317 
								8.57316 8.93623 8.46791C8.97929 8.36267 9.00096 8.2499 8.99997 8.1362C8.99898 8.02249 8.97536 7.91012 8.93048 
								7.80564C8.88559 7.70116 8.82035 7.60667 8.73856 7.52767L5.71132 4.50043Z" fill="#4A525A" />
							</svg>
						</span>
					</label>
				)}
			</Button>
			<CustomTooltip
				anchorSelect={'#move-panel'}
				content={'Move Panel'}
			/>
			<CancelMovePanelModal/>
		</>
	);

}