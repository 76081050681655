import Input from 'components/Basic/Input';
import { SetbacksContainer, SetbackItem, SetbackLabel, SetbackInput, SetbackUnit, Label, SetbackError, SetbackInputWithLabel } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { DesignSettings } from 'store/slices/DesignSettingsSlice';
import { useState } from 'react';

const MIN_SETBACK_LIMIT = 0, MAX_SETBACK_LIMIT = 100; //in inches;
const ERROR_MSG= {
	'required': 'Setback value is required.',
	'min': `Setback value cannot be less than ${MIN_SETBACK_LIMIT} inches.`,
	'max': `Setback value must not exceed ${MAX_SETBACK_LIMIT} inches.`,
};

type Props = {
	onSetbackChnage: (setback: Partial<DesignSettings['setbacks']>, isValid: boolean) => void;
}

type SetbackInoutProps = {
	onChange: (setback: Partial<DesignSettings['setbacks']>, isValid: boolean) => void;
	label: string;
	value: number;
	id: keyof DesignSettings['setbacks'];
}

function SetbackInputField({ label, id, value, onChange }: SetbackInoutProps) {

	const [setbackError, setSetbackError] = useState<{ [K in keyof Partial<DesignSettings['setbacks']>]: string }>({
		gutterSetback: '', nonGutterSetback: '', obstructionSetback: ''
	});

	function handleSetbackInputChange(evt: React.ChangeEvent<HTMLInputElement>) {
		const val = +evt.target.value;
		const errorMsg = { [evt.target.id]: '' };

		if (!evt.target.value) errorMsg[evt.target.id] = ERROR_MSG['required'];
		else if (val < MIN_SETBACK_LIMIT) errorMsg[evt.target.id] = ERROR_MSG['min'];
		else if (val > MAX_SETBACK_LIMIT) errorMsg[evt.target.id] = ERROR_MSG['max'];

		setSetbackError(errorMsg);

		onChange({ [evt.target.id]: (val), }, !errorMsg[evt.target.id]);
	}

	return (
		<SetbackItem>
			<SetbackInputWithLabel>
				<SetbackLabel>{label}</SetbackLabel>
				<SetbackInput>
					<Input
						id={id}
						type="number"
						defaultValue={value}
						onChange={handleSetbackInputChange}
						min={MIN_SETBACK_LIMIT}
						max={MAX_SETBACK_LIMIT} />
					<SetbackUnit>in</SetbackUnit>
				</SetbackInput>
			</SetbackInputWithLabel>
			<SetbackError>{setbackError[id]}</SetbackError>
		</SetbackItem>
	);
}

export default function Setback({ onSetbackChnage }: Props) {

	const setbacks = useSelector((state: RootState) => state.designSettigs.data.setbacks);
	const { gutterSetback, nonGutterSetback , obstructionSetback} = setbacks;

	return (
		<SetbacksContainer>
			<Label>Setbacks</Label>
			<SetbackInputField
				id='gutterSetback'
				label='Gutter'
				value={gutterSetback}
				onChange={onSetbackChnage}
			/>
			<SetbackInputField
				id='nonGutterSetback'
				label='Other'
				value={nonGutterSetback}
				onChange={onSetbackChnage}
			/>
			<SetbackInputField
				id='obstructionSetback'
				label='Obstructions'
				value={obstructionSetback}
				onChange={onSetbackChnage}
			/>
		</SetbacksContainer>
	);
}