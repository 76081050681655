import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ToolNewPostionSilce, NewlyCreatedFacet, } from './types';
import { changePanel, fetchRoofData } from '../RoofDataSlice/roofDataSlice';
import { initializeState, updateFacetHistory } from './util';

const initialState: { data: ToolNewPostionSilce } = {
	data: initializeState()
};

function resetUnfinalizedState(state: ToolNewPostionSilce) {
	state.newlyCreatedFacets = [];
	state.selectedRoofIndex = null;
	state.selectedUnfinalizedKonvaFacetId = null;
	state.deletedRoofIndexes = [];
	state.userHasModifiedRoofSegs = false;
	state.roofIndexes = [];
	state.hadTransition = false;
	state.deleteObstructionsPopUp = false;
}

export const ToolNewPostionSlice = createSlice({
	name: 'ToolPositionsSlice',
	initialState,
	reducers: {
		setRoofIndexes: (state, action: PayloadAction<{ roofIndexes: string[] }>) => {
			state.data.roofIndexes = action.payload.roofIndexes;
		},
		setSelectedRoofIndex: (state, action: PayloadAction<{ selectedRoofIndex: string }>) => {
			state.data.selectedRoofIndex = action.payload.selectedRoofIndex;
		},
		setDeletedRoofIndexes: (state, action: PayloadAction<{ deletedRoofIndexes: string[] }>) => {
			state.data.deletedRoofIndexes = action.payload.deletedRoofIndexes;
		},
		setNewlyCreatedFacets: (state, action: PayloadAction<{ newlyCreatedFacet: NewlyCreatedFacet; }>) => {
			state.data.newlyCreatedFacets = [
				...state.data.newlyCreatedFacets,
				action.payload.newlyCreatedFacet,
			];
		},
		resetNewlyCreatedFacets: (state) => {
			state.data.newlyCreatedFacets = [];
			state.data.deletedRoofIndexes = [];
		},
		setEditState: (state, action: PayloadAction<{ enabled: boolean }>) => {
			state.data.editModeEnabled = action.payload.enabled;
			state.data.drawModeEnabled = false;
			resetUnfinalizedState(state.data);
		},
		setThreeDState: (state, action: PayloadAction<{ enabled: boolean }>) => {
			state.data.threeDModelEnabled = action.payload.enabled;
			state.data.editModeEnabled = false;
			state.data.drawModeEnabled = false;
			resetUnfinalizedState(state.data);
		},
		setSaveWithPanelColorState: (state, action: PayloadAction<{ enabled: boolean }>) => {
			state.data.saveWithPanelColor = action.payload.enabled;
		},
		setUserHasClickedSave: (state, action: PayloadAction<{ enabled: boolean }>) => {
			state.data.userHasClickedSave = action.payload.enabled;
		},
		setDrawModeState: (state, action: PayloadAction<{ enabled: boolean }>) => {
			state.data.drawModeEnabled = action.payload.enabled;
			state.data.selectedUnfinalizedKonvaFacetId = null;
			state.data.selectedRoofIndex = null;
			state.data.newlyCreatedFacets = [];
			state.data.currentActiveFacetEditMode = action.payload.enabled? 'DRAW_MODE': undefined;
		},
		setUnfinalizedFacet: (state, action: PayloadAction<{ id: number | null, newlyCreatedFacets?: NewlyCreatedFacet[] }>) => {
			const { id: facetID, newlyCreatedFacets } = action.payload;
			state.data.selectedUnfinalizedKonvaFacetId = facetID;
			if (newlyCreatedFacets) {
				state.data.newlyCreatedFacets = newlyCreatedFacets;
			}
		},
		setZoomLevel: (state, action: PayloadAction<{ scaleBy: number, position?: Vector2d }>) => {
			const { scaleBy, position = initialState.data.konvaStagePosition } = action.payload;
			state.data.zoomLevel = scaleBy;
			state.data.konvaStagePosition = position;
		},
		setMoveStage: (state, action: PayloadAction<{ shouldMoveStage: boolean }>) => {
			state.data.moveStage = action.payload.shouldMoveStage;
		},
		setKonvaStagePosition: (state, action: PayloadAction<{ position: Vector2d }>) => {
			state.data.konvaStagePosition = action.payload.position;
		},
		setMouseState: (state, action: PayloadAction<{ mouseOnMap: boolean }>) => {
			state.data.isMouseOnMap = action.payload.mouseOnMap;
		},
		setUserModificationState: (state, action: PayloadAction<{ hasMadeChanges: boolean }>) => {
			state.data.userHasModifiedRoofSegs = action.payload.hasMadeChanges;
		},
		setFullScreenState: (state, action: PayloadAction<{ enabled: boolean }>) => {
			state.data.fullScreenEnabled = action.payload.enabled;
		},
		setProvider: (state, action: PayloadAction<{ currentProvider: ImagerySource }>) => {
			state.data.selectedProvider = action.payload.currentProvider;
			state.data.threeDModelEnabled = false;
		},
		batchUpdateToolState: (state, action: PayloadAction<(Partial<ToolNewPostionSilce>)>) => {
			state.data = {
				...state.data,
				...action.payload
			};
		},
		updateTransition: (state, action: PayloadAction<{currentTransitionState: boolean}>) => {
			state.data.hadTransition = action.payload.currentTransitionState;
		},
		setDeletedObstructionIDs: (state, action: PayloadAction<{obstructionID: string}>)=> {
			const currentDeletedObstructionIDs = [...state.data.deletedObstructionIDs, action.payload.obstructionID];
			const newState = { deletedObstructionIDs: currentDeletedObstructionIDs };
			state.data.deletedObstructionIDs = currentDeletedObstructionIDs;
			updateFacetHistory(state.data.facetHistory, newState);
	
		},
		toggleDeleteObstructionsMode: (state, action: PayloadAction<{enable: boolean}>)=>{
			state.data.deleteObstructionsMode = action.payload.enable;
			state.data.currentActiveFacetEditMode = action.payload.enable? 'DELETE_OBSTRUCTION_MODE': undefined;
		},
		resetDeletedObstructionIDs: (state)=> {
			state.data.deletedObstructionIDs = [];
			state.data.facetHistory= initialState.data.facetHistory;
		},
		undoEditFacetState: (state) => {
			if(!state.data.facetHistory.currentIndex) return;
			state.data.facetHistory.currentIndex -= 1;
			if(state.data.facetHistory.currentIndex < 0) return;
			const previousState = state.data.facetHistory.history[state.data.facetHistory.currentIndex];
			state.data.deletedObstructionIDs = previousState.deletedObstructionIDs;
			
		},
		redoEditFacetState: (state) => {
			if (state.data.facetHistory.currentIndex < state.data.facetHistory.history.length - 1) {
				state.data.facetHistory.currentIndex += 1;
				const nextState = state.data.facetHistory.history[state.data.facetHistory.currentIndex];
				state.data.deletedObstructionIDs = nextState?.deletedObstructionIDs || [];
			}
		},
		showDeleteObstructionsPopUp: (state, action: PayloadAction<{enable: boolean}>) => {
			state.data.deleteObstructionsPopUp = action.payload.enable;
		}
	}, extraReducers: (builder,) => {
		builder.addCase(changePanel.fulfilled, (state) => {
			state.data = {
				...state.data,
				newlyCreatedFacets: [],
				// deletedRoofIndexes:[],
				selectedRoofIndex: null,
				selectedUnfinalizedKonvaFacetId: null,
				drawModeEnabled: false,
				userHasModifiedRoofSegs: false,
				currentActiveFacetEditMode: undefined,
				deletedObstructionIDs: [],
				facetHistory: initialState.data.facetHistory,
				deleteObstructionsMode: false
			};
		}).addCase(fetchRoofData.fulfilled, (state, action) => {
			state.data = {
				...state.data,
				zoomLevel: action.payload.toolEditConfig.zoomLevel,
				selectedProvider: action.payload.roofData.imagerySource || initialState.data.selectedProvider,
			};
		}).addCase(changePanel.rejected, (state, action) => {
			if(action.meta.arg.switchingSource){
				state.data.selectedProvider= initialState.data.selectedProvider;
				state.data.currentActiveFacetEditMode = undefined;
			}
		});
	}
});

export const {
	setRoofIndexes, setEditState, setThreeDState, setSaveWithPanelColorState, setUserHasClickedSave,
	setSelectedRoofIndex, setDeletedRoofIndexes,
	setNewlyCreatedFacets, resetNewlyCreatedFacets, setUnfinalizedFacet, setDrawModeState, setZoomLevel, setMoveStage, setMouseState,
	setKonvaStagePosition, setUserModificationState, setProvider, setFullScreenState, 
	batchUpdateToolState, updateTransition, setDeletedObstructionIDs, 
	toggleDeleteObstructionsMode, resetDeletedObstructionIDs, undoEditFacetState, redoEditFacetState,
	showDeleteObstructionsPopUp
} = ToolNewPostionSlice.actions;

export default ToolNewPostionSlice.reducer;