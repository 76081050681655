import Button from 'components/Basic/Button';
import { useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'store';
import { addNewPanels, createNewFacets, movablePanels, removeIndividualPanels, translatePanels } from './api';
import { changePanel } from 'store/slices/RoofDataSlice/roofDataSlice';
import { deleteAllFacetsFromPanelDrawer, getAllKonvaGroups, resetTransformations } from 'components/tool/util';
import { useRefs } from 'contexts/RefContext';
import { transformPointsToOriginalScale } from 'components/DisplayEnergy/util';
import { batchUpdateToolState, setDeletedRoofIndexes, setRoofIndexes, setUserModificationState, 
	updateTransition } from 'store/slices/ToolSlice';
import { setAddPanel, setDeleteIndividualPanelsMode, setPanelMove, } from 'store/slices/PanelSlice';
import { useKonvaScale } from 'hooks/useKonvaScale';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { deleteObstructions } from './Facet/DeleteObstructionBtn/api';
import Konva from 'konva';
import { getAbsolutePointsOfTranslatedPanels } from '../util';
import { toast } from 'react-toastify';

export default function CreateBtn() {

	const { newlyCreatedFacets, deletedRoofIndexes, deletedObstructionIDs, roofIndexes } = 
	useSelector((state: RootState) => state.toolNewPostions.data);
	const { activePanelMode, newlyAddedPanels, deletedPanels, currentMovablePanels } = useSelector((state: RootState) => state.panelSlice.data);
	const { uuid, panel, imagerySource, allRoofSegs } = useSelector((state: RootState) => state.roofData.data);

	const { konvaRef } = useRefs();
	const scale= useKonvaScale();
	const dispatch = useDispatch<AppDispatch>();
	const showLoader = useQueriesRunningInBackGround([
		'createNewFacets', 'addPanels', 'removePanels', 'movablePanels', 'deleteObstructions', 'translate-panels']);
	const disableOnPartialFacet = !activePanelMode && !roofIndexes?.length && 
		!(newlyCreatedFacets.length > 0 || deletedRoofIndexes.length > 0 || deletedObstructionIDs.length > 0);
	const disableOnPartialPanel = activePanelMode && !(newlyAddedPanels.length > 0  || deletedPanels.length > 0 || currentMovablePanels.length > 0);

	function getNewFacets() {
		if((!newlyCreatedFacets.length && !deletedRoofIndexes.length)){
			return;
		}

		const transformedFacets = transformFacets();
		return {
			transformedFacets,
			deletedRoofIndexes
		};
	}

	function transformFacets() {
		return newlyCreatedFacets.map(facet => ({
			hullCoords: facet.hullCoords.map(coord => (transformPointsToOriginalScale(scale,coord)))
		}));
	}

	function deleteFacet() {
		if (!konvaRef?.current) return;
		deleteAllFacetsFromPanelDrawer(konvaRef.current);
	}

	async function fetchUpdatedDesign(){
		await dispatch(changePanel({ uuid, panelKey: panel.key, source: imagerySource }));
	}

	function resetAllMOdifiedState(){
		dispatch(setPanelMove({ shouldEnablePanelMove: false }));
		dispatch(setAddPanel({ shouldEnable: false }));
		dispatch(updateTransition({ currentTransitionState: false }));
		dispatch(setDeletedRoofIndexes({deletedRoofIndexes:[]}));
		dispatch(setRoofIndexes({ roofIndexes: [] }));
		dispatch(setDeleteIndividualPanelsMode({enabled: false}));
		if (konvaRef?.current) {
			(konvaRef.current?.findOne('Transformer') as Konva.Transformer).setNodes([]);
			resetTransformations(getAllKonvaGroups(konvaRef.current));
		}
	}

	const createNewFacetsQuery = useQuery(
		'createNewFacets',
		async () => {
			const newFacets = getNewFacets();
			if (!newFacets) return;
			await createNewFacets({
				newPanelFacets: newFacets.transformedFacets,
				deletedRoofSegs: deletedRoofIndexes,
			}, panel.key, uuid);
			await fetchUpdatedDesign();
			deleteFacet();
		},
		{
			onSuccess: async () => {
				dispatch(setDeletedRoofIndexes({deletedRoofIndexes:[]}));
				dispatch(setUserModificationState({hasMadeChanges: true}));
			},
			onError: (error: Error) => {
				if (error.message === 'E_NO_TRANSLATIONS_TO_SAVE') return;
			},
			// retry: (failureCount, error: Error) => {
			// 	return error.message !== 'E_NO_TRANSLATIONS_TO_SAVE';
			// },
			retry: 0,
			enabled: false,
		}
	);

	const addNewPanelsQuery = useQuery(
		'addPanels',
		async () => {
			if(!konvaRef?.current || !newlyAddedPanels.length) throw new Error('E_NO_PNEL_TO_ADD');
			await addNewPanels(newlyAddedPanels, panel.key, uuid);
			await fetchUpdatedDesign();
		},
		{
			onSuccess: () => resetAllMOdifiedState(),
			onError: (error: Error) => {
				console.log(error);
			},
			retry: 0,
			enabled: false,
		}
	);

	const removePanelsQuery = useQuery(
		'removePanels',
		async () => {
			await removeIndividualPanels(deletedPanels, panel.key, uuid);
			await fetchUpdatedDesign();
		},
		{
			onSuccess: () => resetAllMOdifiedState(),
			onError: (error: Error) => {
				console.log(error);
			},
			retry: 0,
			enabled: false,
		}
	);

	const movablePanelsQuery = useQuery(
		'movablePanels',
		async () => {
			await movablePanels(currentMovablePanels, panel.key, uuid);
			await fetchUpdatedDesign();
		},
		{
			onSuccess: () => resetAllMOdifiedState(),
			onError: (error: Error) => {
				console.log(error);
			},
			retry: 0,
			enabled: false,
		}
	);

	const deleteObstructionsQuery = useQuery(
		'deleteObstructions',
		async () => {
			if (!deletedObstructionIDs?.length) return;
			await deleteObstructions( uuid, deletedObstructionIDs);
			await fetchUpdatedDesign();
		},
		{
			onSuccess: async () => {
				dispatch(batchUpdateToolState({
					deletedObstructionIDs: [],
					deleteObstructionsMode: false,
					userHasModifiedRoofSegs: true,
					currentActiveFacetEditMode: undefined,
					facetHistory: { currentIndex: 0, history: [] }
				}));
			},
			retry: 0,
			enabled: false,
		});

	const translatePanelPostionsQuery = useQuery(
		'translate-panels',
		async () => {
			if (!konvaRef?.current) return;
			//check any facet to delete
			if (deletedRoofIndexes.length) {
				await createNewFacets({ newPanelFacets: [], deletedRoofSegs: deletedRoofIndexes }, panel.key, uuid);
			}

			const newTranslatedPostions = getAbsolutePointsOfTranslatedPanels(konvaRef.current, roofIndexes, allRoofSegs, scale);
			if (!newTranslatedPostions.length) {
				//if the translated facet was deleted
				if (deletedRoofIndexes.length) await fetchUpdatedDesign();
				throw new Error('E_NO_TRANSLATIONS_TO_SAVE');
			}

			await translatePanels(newTranslatedPostions, panel.key, uuid);
			await fetchUpdatedDesign();
		},
		{
			onSuccess: () => resetAllMOdifiedState(),

			onError: async (error: Error) => {
				if (error.message === 'E_NO_TRANSLATIONS_TO_SAVE' || error.message === 'E_WHILE_GETTING_TRANSLATION_FOR_SEGMENT') {
					resetAllMOdifiedState();
					return;
				}
				toast.error('something went wrong, please try again');
			},
			retry: (failureCount, error: Error) => {
				return error.message !== 'E_NO_TRANSLATIONS_TO_SAVE' && failureCount < 2;
			},
			enabled: false,
		}
	);

	function handleClick(){
		if (newlyAddedPanels.length) {
			addNewPanelsQuery.refetch();
		} else if (deletedPanels.length) {
			removePanelsQuery.refetch();
		} else if (currentMovablePanels.length) {
			movablePanelsQuery.refetch();
		} else if(deletedObstructionIDs.length){
			deleteObstructionsQuery.refetch();
		}else if(roofIndexes.length) {
			translatePanelPostionsQuery.refetch();
		}else {
			createNewFacetsQuery.refetch();
		}
	}

	return (
		<Button
			onClick={handleClick}
			style={{
				background: `${newlyCreatedFacets ? '#003CFF' : '#fff'}`,
				border: '1px solid rgba(0, 60, 255, 0.5)',
				// borderRight:'0px',
				// borderLeft:'0px',
				color: `${newlyCreatedFacets ? '#fff' : '#28373E'}`,
				fontSize: '0.875rem',
				padding: '0px',
				height: '36px',
				minWidth: '86px',
				borderRadius:'20px',
				boxShadow: 'rgba(100,100,100,0.12) 0px 2.25911px 4px',
			}}
			showLoader={showLoader}
			disabled={!!showLoader || disableOnPartialFacet || disableOnPartialPanel}
			className='loaderbefore'
		>
			<svg width="17" height="17" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M6.33398 7.22179L9.22998 9.39423C9.4094 9.52879 9.63305 9.59056 9.85609 9.56715C10.0791
				 9.54374 10.2851 9.43689 10.4327 9.26801L16.1118 2.77734" stroke={`${newlyCreatedFacets ? '#fff' : '#4A525A'}`} strokeWidth="1.6" strokeLinecap="round"/>
				<path d="M17 8.99986C17 10.6714 16.4765 12.301 15.5028 13.6598C14.5292 15.0185 13.1544 16.0382 
				11.5715 16.5755C9.98861 17.1128 8.2772 17.1407 6.6776 16.6555C5.07801 16.1702 3.6706 15.1961
				 2.65304 13.8699C1.63549 12.5437 1.0589 10.9321 1.00428 9.26145C0.949647 7.59076 1.41972 5.94493 
				 2.34847 4.55511C3.27721 3.16529 4.61798 2.1013 6.18246 1.51258C7.74693 0.923864 9.45652 0.839991 
				 11.0711 1.27274" stroke={`${newlyCreatedFacets ? '#fff' : '#4A525A'}`} strokeWidth="1.6" strokeLinecap="round"/>
			</svg> Done
		</Button>

	);

}