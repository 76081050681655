export function decimetersToInches(dm: number) {
	return +(dm * 3.937).toFixed(3);
}

export function inchesToDecimeters(inches: number) {
	return +(inches * 0.254).toFixed(3);
}

export function negateValue(num: number) {
	return num*-1;
}